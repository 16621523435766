<template>
  <v-card :loading="loading" class="mx-auto my-12 shadow" max-width="374" :href="'/tickets/' + item.event._id"
            @click="$router.push('/tickets/' + item.event._id).catch(() => {})">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img height="250" :src="getImage(item.event.img)"></v-img>

    <v-card-title style="height: 100px; overflow: hidden">{{ item.event.title }}</v-card-title>

    <v-card-text>
      <div class="grey--text">
        {{ item.event.date | moment("DD.MM.YYYY HH:mm") }} Uhr
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Verfügbare Karten:</v-card-title>

    <v-card-text>
      <v-chip-group active-class="deep-purple accent-4 white--text" column>
        <v-chip
          >{{ item.participants.length }}</v-chip
        >
      </v-chip-group>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <v-btn color="success lighten-2" text>
        Jetzt Ansehen
      </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>
export default {
  name: 'TicketCard',
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>