<template>
  <v-card class="mx-auto my-12 shadow">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-row justify="center" class="pt-10">

        <qrcode-vue :margin="0" :value="item._id" :size="size" level="H"></qrcode-vue>
       
    </v-row>
  
    <v-card-title  class="text-ce"></v-card-title>
    <h3 style="height: 50px; overflow: hidden" class="mx-3 text-center">
      {{ item.event.title }}
    </h3>
    <v-card-text>
      <div>
        <p class="text-center">{{ item.participant_data.name }}</p>
      </div>
      <div>
        <p class="text-center">{{ item.participant_data.street }}</p>
      </div>
      <div>
        <p class="text-center">{{ item.participant_data.zipcode }}, {{ item.participant_data.city }}</p>
      </div>      
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title class="justify-center">Ticketstatus:</v-card-title>

    <v-card-text class="justify-center">
      <v-row class="mx-5 my-3" justify="center">
        <v-chip :color="!item.status ? 'green' : 'red'"
          >{{ item.status ? 'Eingelöst' : 'Gültig' }}</v-chip
        >
      </v-row>
      <!-- <v-chip-group active-class="success accent-4 white--text" column>
        <v-chip :color="!item.status ? 'green' : 'red'"
          >{{ item.status ? 'Eingelöst' : 'Gültig' }}</v-chip
        >
      </v-chip-group> -->
    </v-card-text>

    <!-- <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <v-btn color="success lighten-2" text>
        Jetzt Ansehen
      </v-btn>
    </v-card-actions> -->
  </v-card>

</template>

<script>
import QrcodeVue from "../../plugins/qrcode.vue";
export default {
  name: 'TicketCardQR',
  components: {
    QrcodeVue,
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      size: 250,
      loading: false,
    };
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>