<template>
  <div>
     <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6">
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <dropzone @newFile="setFile"></dropzone>
              </div>
            </div>

            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                    Titelbild hochladen
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                News Informationen
              </h5>
              <v-form>
                <div class="ps-lg-5 mt-5">
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Newstitel</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        outlined
                        v-model="newNews.title"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Urheber- und Fotoinformationen</label
                      >
                      <v-text-field
                        outlined
                        v-model="newNews.disclaimer"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Newscontent</label
                      >
                      <vue-editor v-model="newNews.description"></vue-editor>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <v-row class="">
                <v-col>
                  <v-row align="center" justify="space-around">
                    <v-btn
                      elevation="0"
                      height="43"
                      :disabled="checkForm"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-primary
                        py-2
                        px-6
                        me-2
                        mb-sm-0 mb-2
                      "
                      color="#5e72e4"
                      small
                      @click="postNews"
                      >News anlegen</v-btn
                    >
                    <v-btn
                      elevation="0"
                      height="43"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-secondary
                        py-2
                        px-6
                        me-2
                        mb-sm-0 mb-2
                      "
                      color="#5e72e4"
                      small
                      @click="$router.go(-1).catch(() => {})"
                      >Abbrechen</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600"
              >Aktion erfolgreich!</span
            >
            <br />
            News angelegt!
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";

import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "NewEvent",
  mixins: [coreData],
  components: {
    VueEditor,
    Dropzone,
  },
  data: function () {
    return {
      snackbar: {
        color: null,
        visible: false,
      },
      newNews: {
        sponsors: [],
        hint: " ",
        html: " ",
      },
    };
  },
  computed: {
    checkForm() {
      if(this.newNews.title && this.newNews.description) return false
      return true
    }
  },
  methods: {
    setFile(file) {
      console.log("file emitted!");
      this.newNews.file = file;
    },
    getImage(link) {
      if (!link) return null;
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },

    SnackbarShow(type) {
      if (!type) return;
      switch (type) {
        case "Info":
          this.snackbar = {
            color: "#1A73E8",
            visible: true,
          };
          break;
        case "Success":
          this.snackbar = {
            color: "#4CAF50",
            visible: true,
          };
          break;
        case "Warning":
          this.snackbar = {
            color: "#fb8c00",
            visible: true,
          };
          break;
        case "Danger":
          this.snackbar = {
            color: "#F44335",
            visible: true,
          };
          break;
      }
    },

    postNews() {
      if (!this.newNews.title || !this.newNews.description) return;
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("description", this.newNews.description);
        formData.append("title", this.newNews.title);
        formData.append("disclaimer", this.newNews.disclaimer);
        formData.append("manager", this.userInformations._id);
        if (this.newNews.file) {
          formData.append("image", this.newNews.file);
        }
        formData.append("date", this.newNews.date);

        axiosAuth
          .post("eventnews/", formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("getEventNewsAdmin");
            this.newNewsModal = false;
            this.SnackbarShow('Success')
            this.$router.go(-1).catch(() => {})
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            console.log(error.response);
            reject(error.response.data);
            this.SnackbarShow('Danger')
          });
      });
    },
  },
};
</script>
