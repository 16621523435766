<template>
  <v-card
    :loading="loading"
    class="mx-auto my-12 shadow"
    width="100%"
    :to="'/news/' + (item.url ? item.url : item._id)"
    style="border-radius: 20px !important"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div class="" style="position: relative">
      <v-img
        class="mt-3 mt-sm-0"
        style="border-top-left-radius: 20px; border-top-right-radius: 20px"
        cover
        height="250"
        :src="getImage(item.img)"
      ></v-img>
      <div
        class="border-radius-xl dark--text blur px-2 shadow-blur bg-dark theme--light"
        style="position: absolute; bottom: 15px; left: 15px; font-size: 20px"
      >
        {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
      </div>
    </div>

    <v-card-text>
      <div class="text-h5 text-dark" style="height: 70px">
        {{ item.title }}
      </div>
      <div
        class="text-start font-weight-normal text-typo"
        style="height: 70px; overflow: hidden"
        v-html="
          item.description.length > 150
            ? item.description.substr(0, 150) + ' ...'
            : item.description
        "
      ></div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "NewsCard",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>