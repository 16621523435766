<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-col cols="12" md="8">
      <v-container class="mb-15">
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 35px"
        >
          Hoppla.... <br />
          Wir haben diese Seite nicht gefunden...
        </h2>
        <v-row justify="center">
          <div>
            <v-card @click="$router.push('/')">
              <v-img
                height="100%"
                width="400px"
                :src="errorImage"
                class="img-fluid border-radius-lg"
                :alt="errorImage"
              />
            </v-card>
          </div>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "404",
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
    };
  },
};
</script>
