<template>
  <v-dialog v-model="open" max-width="500px">
    <v-card class="card-shadow card-padding border-radius-xl">
      <v-card-title
        class="pt-0 text-h5 text-typo font-weight-normal justify-center"
        >Möchten Sie diesen Eintrag wirklich löschen?</v-card-title
      >
      <v-card-actions class="pb-0">
        <v-spacer></v-spacer>
        <v-btn
          @click="$emit('close')"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-normal
            text-capitalize
            btn-ls btn-outline-secondary
            bg-transparent
            py-3
            px-6
          "
          >Abbrechen</v-btn
        >

        <v-btn
          @click="$emit('confirm')"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-normal
            text-capitalize
            btn-ls btn-primary
            bg-gradient-primary
            py-3
            px-6
          "
          >Löschen</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteDialog",
  props: {
    open: Boolean,
  },
};
</script>
