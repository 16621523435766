<template>
  <v-row class="align-center d-flex h-100">
    <v-col lg="4" md="7" class="mx-auto">
      <v-card class="card-shadow border-radius-xl py-1">
        <div
          class="
            card-padding
            text-center
            mt-n7
            bg-gradient-success
            shadow-success
            border-radius-lg
            mx-4
          "
        >
          <h4 class="text-h4 text-white font-weight-bold">Login</h4>
        </div>
       
        <div class="card-padding">
  
          <p>{{ $route.query.message == 'notAuthorized' ? 'Nicht authorisiert!' : '' }}</p>
          <v-text-field
            label="Email"
            placeholder="Email"
            color="#e91e63"
            required
            v-model="form.email"
            class="font-size-input input-style"
            :rules="rules"
          ></v-text-field>

          <v-text-field
            label="Password"
            placeholder="Password"
            color="#e91e63"
            required
            v-model="form.password"
            type="password"
            class="font-size-input input-style"
            :rules="rules"
          ></v-text-field>

          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-6
              mb-2
              w-100
            "
            color="#5e72e4"
            small
            @click="submitForm"
            >Login</v-btn
          >
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "sign-up-basic",
  data() {
    return {
      form: {

      },
      rules: [
        value => !!value || 'Erforderlich.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
    };
  },
  computed: {
    authError(){
      return this.$store.getters.authError
    },
    authMessage(){
      return this.$store.getters.authMessage
    },
    darkMode(){
      return this.$store.getters.darkMode
    },
    contactFormSent(){
      return this.$store.getters.contactFormSent
    }
  },
  mounted(){
   
  },
  methods: {
    submitForm(){
      var payload = {
        email: this.form.email,
        password: this.form.password
      }
      this.$store.dispatch('login', payload)
    } 
  },
};
</script>
