const administrationData = {
    filters: {

    },
    data() {
        return {
            newPushnotification: {},
            snackbar: {
                color: null,
                visible: false,
                title: null,
                message: null
            },
        }
    },
    computed: {
        emailResended() {
            return this.$store.getters.emailResended;
        },
        userInformations() {
            return this.$store.getters.userInformations;
        },
        clients() {
            return this.$store.getters.clients;
        },
        updatingClients() {
            return this.$store.getters.updatingClients;
        },
        clientEvents() {
            return this.$store.getters.clientEvents;
        },
        clientEventNotifications() {
            return this.$store.getters.clientEventNotifications;
        },
        clientEventEntries() {
            return this.$store.getters.clientEventEntries;
        },
        clientEventInvoices() {
            return this.$store.getters.clientEventInvoices;
        },
        updatingClientEvents() {
            return this.$store.getters.updatingClientEvents;
        },
        updatingClientInvoices() {
            return this.$store.getters.updatingClientInvoices;
        },
        selectedClientEvent() {
            return this.$store.getters.selectedClientEvent;
        },
        getYears() {
            let years = [];
            var thisYear = new Date().getFullYear();

            for (let index = 2021; index <= thisYear; index++) {
                years.push(index);
            }
            return years;
        },
        currentYear() {
            var now = new Date();
            return now.getFullYear();
        },
        selectedYear() {
            return this.$store.getters.selectedYear;
        },
    },

    mounted() {

    },

    methods: {
        csvExportEntries(event, evententries) {
            let arrData = evententries
            let csvData = []
            arrData.forEach(element => {
                let entryData = {
                    "Eventname": event.title,
                    "Rechnungsnummer": element.payment_id[0].invoice_nr,
                    "Ticketpersonalisierung": element.participant_data.name,
                    "Nachname": element.lastname,
                    "Vorname": element.firstname,
                    "Straße": element.street,
                    "PLZ": element.zip.toString(),
                    "E-Mail": element.email,
                    "Rechnungsdatum": element.payment_id[0].update_time,
                    "Transaktionscode": element.payment_id[0].transactions[0].related_resources[0].sale.id,
                    "Ticketpreis in EUR": element.participant_data.price_lowered ? event.price_lowered.toFixed(2).replace(".", ",") : event.price.toFixed(2).replace(".", ","),
                    "Ermäßigt": element.participant_data.price_lowered ? "Ja" : "Nein",
                    "Ticket eingelöst": element.redeemed ? "Ja" : "Nein",
                    "Zeitstempel einlösung": element.redeemed ? element.updated : "n/a"
                }

                csvData.push(entryData)
            })

            // arrData = JSON.parse(JSON.stringify(arrData))
            arrData.forEach(element => {
                if (element.redeemed == undefined) {
                    element.redeemed = false
                }
                delete element.participants;
                delete element.participant_data;
                delete element.edit;
                delete element._id;
                delete element.event;
                delete element.dsgvo_accepted;
                delete element.__v;
                delete element.updated;
            })
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(csvData[0]).join(";"),
                ...csvData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", event.url + "_export.csv");
            link.click();
        },
        csvExportYear(invoices, year, client) {

            let csvData = []
            invoices.forEach(element => {
                let entryData = {
                    "Eventname": element.event.title,
                    "Rechnungsnummer": element.invoice_nr,
                    "Rechnungsdatum": element.update_time,
                    "Nachname": element.eventPayload.lastname,
                    "Vorname": element.eventPayload.firstname,
                    "Straße": element.eventPayload.street,
                    "PLZ": element.eventPayload.zip,
                    "E-Mail": element.eventPayload.email,
                    "Transaktionscode": element.transactions[0].related_resources[0].sale.id,
                    "Mehrwertsteuer in %": element.event.vat_value,
                    "Gesamtpreis in EUR": element.transactions[0].amount.total.toString().replace('.', ','),
                }
                csvData.push(entryData)
            })

            invoices.forEach(element => {
                if (element.redeemed == undefined) {
                    element.redeemed = false
                }
                delete element.participants;
                delete element.participant_data;
                delete element.edit;
                delete element._id;
                delete element.event;
                delete element.dsgvo_accepted;
                delete element.__v;
                delete element.updated;
            })
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += [
                Object.keys(csvData[0]).join(";"),
                ...csvData.map(item => Object.values(item).join(";"))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "");

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", year + "_" + client.companyName + "_export.csv");
            link.click();
        },
        downloadInvoice(invoice) {
            this.$store.dispatch("downloadInvoice", invoice);
        },
        getPaypalState(state) {
            switch (state) {
                case "completed":
                    return "ABGESCHLOSSEN";
                case "refunded":
                    return "ERSTATTET";
                default:
                    return state;
            }
        },
        resendEmail(eventEntry) {
            this.$store.dispatch("resendEmail", eventEntry);
        },
        updateEventEntry(eventEntry) {
            console.log(eventEntry)
            this.$store.dispatch("updateEventEntry", eventEntry);
            // this.$store.dispatch("getEventEntries");
        },
        getImage(link) {
            return process.env.VUE_APP_API_BASEURL + '/' + link
        },
        SnackbarShow(type, title, message) {
            if (!type) return;
            switch (type) {
                case "Info":
                    this.snackbar = {
                        color: "#1A73E8",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
                case "Success":
                    this.snackbar = {
                        color: "#4CAF50",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
                case "Warning":
                    this.snackbar = {
                        color: "#fb8c00",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
                case "Danger":
                    this.snackbar = {
                        color: "#F44335",
                        visible: true,
                        title: title,
                        message: message
                    };
                    break;
            }
        },
    }
}

export default administrationData