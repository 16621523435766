<template>
  <v-card
    class="card card-shadow border-radius-xl py-5 text-center"
    data-animation="true"
  >
    <div class="mt-n11 mx-4 card-header position-relative z-index-2">
      <div class="d-block blur-shadow-image">
        <img
          :src="getImage(event.img)"
          class="img-fluid shadow border-radius-lg"
          :alt="event.image"
        />
      </div>
      <div
        class="colored-shadow"
        v-bind:style="{
          backgroundImage: 'url(' + getImage(event.img) + ')',
        }"
      ></div>
    </div>
    <div class="d-flex mx-auto mt-n8">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click="
              $router
                .push('/admin/administration/events/details/' + event._id)
                .catch(() => {})
            "
            class="material-icons-round text-primary ms-auto px-5"
            size="18"
          >
            info
          </v-icon>
        </template>
        <span>Details</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            @click="
              $router.push('/admin/events/edit/' + event._id).catch(() => {})
            "
            v-bind="attrs"
            v-on="on"
            class="material-icons-round text-info me-auto px-5"
            size="18"
          >
            edit
          </v-icon>
        </template>
        <span>Bearbeiten</span>
      </v-tooltip>
    </div>
    <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
      <a href="javascript:;" class="text-decoration-none text-default">{{
        event.title
      }}</a>
    </h5>
    <p class="mb-0 text-body font-weight-light px-5">
      {{ event.text }}
    </p>
    <hr class="horizontal dark my-6" />
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">Anmeldungen:</p>
      <i
        class="material-icons-round position-relative ms-auto text-lg me-1 my-auto"
        >person</i
      >
      <p class="text-sm my-auto font-weight-light">
        {{ event.entries.length }}
      </p>
    </div>
    <div class="d-flex mb-4 mx-2 px-4 mt-4">
      <p class="mb-0 text-body font-weight-light">
        {{ event.active ? "Event aktiv" : "Event inaktiv" }}
      </p>
      <v-switch
        disabled
        :ripple="false"
        class="d-inline-flex mt-0 pt-0 switch ms-auto"
        v-model="event.active"
        hide-details
      ></v-switch>
    </div>
    <div class="d-flex mb-4 mx-2 px-4 mt-4">
      <p class="mb-0 text-body font-weight-light">
        {{ event.entries_active ? "Anmeldung aktiv" : "Anmeldung inaktiv" }}
      </p>
      <v-switch
        disabled
        :ripple="false"
        class="d-inline-flex mt-0 pt-0 switch ms-auto"
        v-model="event.entries_active"
        hide-details
      ></v-switch>
    </div>
    <hr class="horizontal dark my-6" />
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">Datum:</p>
      <p class="text-sm my-auto font-weight-light ml-auto">
        {{ `${event.date}` | moment("DD.MM.YYYY HH:mm") }} Uhr
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "EventCard",
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>