<template>
  <div>
    <loading-overlay v-if="updatingClients" :text="''" />
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-row class="mt-0 ml-1" justify="space-between">
            <!-- <v-btn
              elevation="0"
              height="43"
              class="font-weight-bold text-uppercase btn-default bg-gradient-primary py-2 px-6 me-2 mb-sm-0 mb-2"
              color="#5e72e4"
              small
              @click="$router.push('/admin/events/new').catch(() => {})"
              >Neuen Kunden anlegen</v-btn
            > -->
            <div class="mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="$store.dispatch('getClients')"
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-primary ms-auto"
                    size="30"
                  >
                    refresh
                  </v-icon>
                </template>
                <span>Aktualisieren</span>
              </v-tooltip>
            </div>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row class="mb-6 mt-3">
            <v-col
              v-for="client in clients"
              :key="client.Id"
              cols="12"
              md="6"
              lg="4"
              class="pt-0 mb-10"
            >
              <ClientCard :client="client" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import ClientCard from "./Widgets/ClientCard.vue";
import administrationData from "@/mixins/administrationMixin";
export default {
  name: "AdministrationOverview",
  mixins: [administrationData],
  components: {
    ClientCard,
  },
  data: function () {
    return {};
  },
  mounted() {
    this.$store.dispatch("getClients");
    if (!this.selectedYear) {
      this.$store.commit("selectedYear", this.currentYear);
    }
  },
  computed: {},
  methods: {},
};
</script>
