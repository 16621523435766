<template>
  <v-container>
    <v-col cols="12" class="mt-5 mx-auto text-center">
      <h2
        class="mx-3 h1 font-weight-normal text-center text-body"
        style="font-size: 35px"
      >
        Häufige Fragen
      </h2>
      <p class="text-body font-weight-light">
        Wie können wir Dir weiter helfen?
      </p>
    </v-col>

    <v-col lg="8" class="mx-auto">
      <v-expansion-panels flat color="#ddd" class="mb-8">
        <v-expansion-panel
          v-for="panel in panels"
          :key="panel.title"
          class="bg-transparent"
        >
          <v-expansion-panel-header class="border-bottom px-0">
            <h5 class="text-h5 font-weight-bold text-body">
              {{ panel.title }}
            </h5>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <p class="text-body font-weight-light text-sm opacity-8 mt-5">
              {{ panel.description }}
            </p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "Sponsoren",
  props: {
  },
  data() {
    return {
      panels: [
        {
          title: "Ich habe meine Tickets nicht verwendet und das Datum ist vorüber - erhalte ich eine Erstattung?",
          description:
            "Solltest du Deine Eintrittskarten aufgrund externer Umstände (z.B. Schließung der Attraktion) nicht nutzen können, beantrage die Rückerstattung so schnell wie möglich und spätestens 3 Monate nach dem Besuchsdatum. Wenn jedoch keine externen Umstände vorlagen, können Tickets nach Ablauf der Veranstaltung leider nicht mehr erstattet werden.",
        },
        {
          title: "Ich habe die Zahlung während der Bestellung abgebrochen, wurde trotzdem gebucht?",
          description:
            "Den Status Deiner begonnenen Zahlungen kannst du jederzeit unter Mein Bereich -> Zahlungen einsehen",
        },
        {
          title: "Ich habe ein Ticket aufgrund eines Fehlers Doppelt bezahlt, erhalte ich eine Erstattung?",
          description:
            "Solltest du unbeabsichtigt ein oder mehrere Tickets mehrfach bestellt haben, kontaktiere unseren Support um eine Erstattung zu beantragen.",
        },
        {
          title: "Ein(e) Teilnehmer(in) kann nicht an der gebuchten Veranstaltung teilnehmen, kann ich das Ticket übertragen?",
          description:
            "Bei der Buchung der Tickets wirst du gebeten die Namen der Teilnehmer anzugeben, somit sind die Tickets an die jeweilige Person gebunden. Sollte ein(e) Teilnehmer(in) nicht teilnehmen können, kontaktiere rechtzeitig unseren Support um eine Änderung zu beantragen.",
        },
      ],
    };
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>