<template>
  <div>
    <div class="d-sm-flex justify-between mb-5">
      <v-btn
        v-if="filter != ''"
        elevation="0"
        height="43"
        class="font-weight-bold text-uppercase shadow-none btn-default btn-outline-default py-2 px-6 me-2 mb-sm-0 mb-2"
        color="transparent"
        small
        >Filter: {{ filter }}</v-btn
      >
      <div>
        <v-btn
          elevation="0"
          height="43"
          class="font-weight-bold text-uppercase btn-default btn-outline-default shadow-none py-2 px-6 me-2"
          color="transparent"
          small
          @click="showCancelAlert()"
        >
          <v-icon
            class="material-icons-round text-primary ms-auto mr-3"
            size="30"
          >
            refresh
          </v-icon>
          Zahlungen aktualisieren
        </v-btn>
      </div>
      <div class="d-flex ms-auto">
        <v-menu transition="slide-y-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="0"
              height="43"
              class="font-weight-bold text-uppercase btn-default btn-outline-default shadow-none py-2 px-6 me-2"
              color="transparent"
              small
            >
              Filter
              <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0">
            <v-list-item
              class="list-item-hover-active py-2"
              @click="filter = 'Bestätigt'"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  Status: Bestätigt
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="list-item-hover-active pb-2"
              @click="filter = 'Offen'"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  Status: Offen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="list-item-hover-active pb-2"
              @click="filter = 'Storniert'"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  Status: Storniert
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <hr class="horizontal dark" />
            <v-list-item
              class="list-item-hover-active py-2"
              @click="filter = ''"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-danger ls-0 font-weight-600 mb-0"
                >
                  Filter entfernen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          elevation="0"
          height="43"
          class="font-weight-bold text-uppercase btn-default btn-outline-default shadow-none py-2 px-6 me-2"
          color="transparent"
          small
          @click="csvExportEntries(selectedClientEvent, clientEventEntries)"
        >
          <v-icon>ni ni-archive-2 me-1</v-icon>
          Export CSV
        </v-btn>
      </div>
    </div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <v-row class="mt-0 ml-1" justify="space-between">
          <h5 class="font-weight-bolder text-h5 text-typo">
            {{
              clientEventInvoices.filter((invoice) =>
                filter != ""
                  ? filter == "Storniert"
                    ? invoice.canceled == true
                    : filter == "Bestätigt"
                    ? invoice.state == "approved"
                    : invoice.state == "created"
                  : true
              ).length
            }}
            Zahlungsvorgänge
          </h5>

          <div class="mr-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="
                    $store.dispatch('getClientEventInvoices', {
                      id: $route.params.id,
                    })
                  "
                  v-bind="attrs"
                  v-on="on"
                  class="material-icons-round text-primary ms-auto"
                  size="30"
                >
                  refresh
                </v-icon>
              </template>
              <span>Aktualisieren</span>
            </v-tooltip>
          </div>
        </v-row>
        <!-- <h5 class="font-weight-bold text-h5 text-typo mb-0">
          {{ selectedClientEvent.entries.length }} Anmeldungen
        </h5> -->
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="
            clientEventInvoices.filter((invoice) =>
              filter != ''
                ? filter == 'Storniert'
                  ? invoice.canceled == true
                  : filter == 'Bestätigt'
                  ? invoice.state == 'approved'
                  : invoice.state == 'created'
                : true
            )
          "
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
          item-key="_id"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Suche"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size="16"
                        class="material-icons-round mt-n2"
                        >search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="600px">
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">{{
                      formTitle
                    }}</span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.eventPayload.firstname"
                            hide-details
                            disabled
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Vorname"
                            label="Vorname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            disabled
                            v-model="editedItem.eventPayload.lastname"
                            hide-details
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Nachname"
                            label="Nachname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.eventPayload.email"
                            hide-details
                            disabled
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="E-Mail-Adresse"
                            label="E-Mail-Adresse"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.eventPayload.street"
                            hide-details
                            disabled
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Straße"
                            label="Straße"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.eventPayload.number"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Hausnummer"
                            label="Hausnummer"
                          ></v-text-field>
                        </v-col> -->
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.eventPayload.zip"
                            hide-details
                            disabled
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Postleitzahl"
                            label="Postleitzahl"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.eventPayload.city"
                            hide-details
                            disabled
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Ort"
                            label="Ort"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" v-if="editedItem.transactions">
                          <v-text-field
                            v-model="
                              editedItem.transactions[0].related_resources[0]
                                .sale.id
                            "
                            hide-details
                            disabled
                            class="input-style font-size-input text-light-input placeholder-light input-icon"
                            dense
                            flat
                            outlined
                            height="43"
                            label="PayPal ID"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="showPaymentAlert()"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      :disabled="
                        refundValidation(editedItem.create_time) ||
                        editedItem.canceled
                      "
                      class="font-weight-normal btn-ls btn-primary bg-danger py-3 px-6"
                      >{{
                        editedItem.canceled
                          ? "Storniert"
                          : !refundValidation(editedItem.create_time)
                          ? "Rechnung stornieren"
                          : "Storno nicht mehr möglich"
                      }}</v-btn
                    >
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
                      >schließen</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="cancelationDialog" max-width="600px">
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0"
                      >Rechnung stornieren</span
                    >
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <span
                        class="font-weight-bold text-h5 text-danger text--danger mb-0"
                        >Möchten Sie die Rechnung wirklich stornieren? Dieser
                        Vorgang kann nicht rückgängig gemacht werden! Es wird
                        eine Rückerstattung bei PayPal angefordert!</span
                      >
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="showPaymentAlert"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-normal text-capitalize btn-ls btn-primary bg-danger py-3 px-6"
                      >Rechnung endgültig stornieren</v-btn
                    >
                    <v-btn
                      @click="closeCancelation"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-normal text-capitalize btn-ls btn-outline-secondary bg-transparent py-3 px-6"
                      >abbrechen</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="updatingStates" persistent max-width="600px">
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0"
                      >Zahlungsvorgänge werden aktualisiert</span
                    >
                  </div>
                  <v-card-text class="card-padding">
                    <span class="font-weight-bold text-body text-typo mb-0"
                      >Dies wird einige Zeit in Anspruch nehmen, schließen Sie
                      dieses Fenster nicht bevor der Vorgang beendet
                      wurde!</span
                    >

                    <v-progress-linear
                      v-if="updatingStates"
                      class="mt-4 mr-5"
                      color="light-blue"
                      height="10"
                      :value="progressValue"
                      rounded
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogNoInvoice" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                    >Keine Rechnung vorhanden</v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeNoInvoice"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-normal text-capitalize btn-ls bg-transparent btn-outline-secondary py-3 px-6"
                      >schließen</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.payer.payer_info.first_name="{ item }">
            <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-normal text-body">
                {{
                  item.payer.payer_info
                    ? item.payer.payer_info.first_name
                    : "- - -"
                }}
              </span>
            </div>
          </template>

          <template v-slot:item.payer.payer_info.last_name="{ item }">
            <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-normal text-body">
                {{
                  item.payer.payer_info
                    ? item.payer.payer_info.last_name
                    : "- - -"
                }}
              </span>
            </div>
          </template>

          <template v-slot:item.invoice_nr="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.invoice_nr ? item.invoice_nr : "- - -" }}
            </span>
          </template>

          <template v-slot:item.create_time="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.create_time | moment("DD.MM.YYYY HH:mm") }}
            </span>
          </template>

          <template v-slot:item.state="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.state }}
            </span>
          </template>

          <template v-slot:item.transactions[0].amount.total="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.transactions[0].amount.total }}€
            </span>
          </template>

          <template v-slot:item.transactions[0].related_resources="{ item }">
            <span
              v-if="item.transactions[0].related_resources"
              class="text-sm font-weight-normal"
              :class="
                getPaypalState(
                  item.transactions[0].related_resources[0].sale.state
                ) != 'ABGESCHLOSSEN'
                  ? 'text-danger'
                  : 'text-success'
              "
            >
              {{
                item.transactions[0].related_resources
                  ? getPaypalState(
                      item.transactions[0].related_resources[0].sale.state
                    )
                  : ""
              }}
            </span>
          </template>

          <template v-slot:item.state="{ item }">
            <div class="d-flex align-center">
              <v-btn
                :ripple="false"
                icon
                outlined
                disabled
                rounded
                width="25px"
                height="25px"
                class="me-2"
                :class="
                  item.canceled
                    ? 'border-danger'
                    : item.state == 'approved'
                    ? 'border-success'
                    : 'border-default'
                "
              >
                <v-icon
                  size="8"
                  :class="
                    item.state == 'approved' ? 'text-success' : 'text-dark'
                  "
                >
                  {{
                    item.canceled
                      ? "fas fa-times"
                      : item.state == "approved"
                      ? "fas fa-check"
                      : "fas fa-times"
                  }}
                </v-icon>
              </v-btn>
              <span
                class="text-body text-xs"
                :class="
                  item.canceled
                    ? 'text-danger'
                    : item.state == 'approved'
                    ? 'text-success'
                    : ''
                "
                >{{
                  item.cancelation_invoice
                    ? "Stornorechnung"
                    : item.canceled
                    ? "Storniert"
                    : item.state == "approved"
                    ? "Bestätigt"
                    : ""
                }}
                {{ item.state == "created" ? "Offen" : "" }}</span
              >
            </div>
          </template>

          <template v-slot:item._id="{ item }">
            <v-btn
              v-if="item.invoice_nr"
              @click="editItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round">search</v-icon>
            </v-btn>

            <v-btn
              v-if="item.invoice_nr"
              @click="downloadInvoiceModal(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round">download</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Einträge pro Seite:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="font-size-input placeholder-lighter text-color-light input-alternative input-focused-alternative input-icon"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#D81B60"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import administrationData from "@/mixins/administrationMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "paginated-tables",
  mixins: [administrationData],
  data() {
    return {
      progressValue: 0,
      updatingStates: false,
      filter: "Bestätigt",
      dialogNoInvoice: false,
      showParticipantsDialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      cancelationDialog: false,
      search: "",
      editedIndex: -1,
      editedItem: {
        eventPayload: {},
      },
      defaultItem: {
        eventPayload: {},
      },
      headers: [
        {
          text: "Vorname",
          value: "payer.payer_info.first_name",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Nachname",
          value: "payer.payer_info.last_name",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Rechnugnsnummer",
          align: "start",
          cellClass: "border-bottom",
          sortable: true,
          value: "invoice_nr",
          class:
            "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Datum",
          value: "create_time",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Status",
          value: "state",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "PayPal Status",
          value: "transactions[0].related_resources",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Betrag",
          value: "transactions[0].amount.total",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Aktionen",
          value: "_id",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  methods: {
    async updatePayments() {
      this.updatingStates = true;
      this.progressValue = 0;
      let invoices = this.clientEventInvoices.filter(
        (invoice) => invoice.state == "approved"
      );
      let counter = 1;
      for (const element of invoices) {
        await axiosAuth
          .get("invoices/paypal-details/" + element.id)
          .then(() => {
            counter += 1;
            console.log(element.id);
            this.progressValue = (100 * counter) / invoices.length;
          })
          .catch((error) => {
            this.SnackbarShow("Danger", "Fehler!");
          });
      }
      this.updatingStates = false;
      this.$store.dispatch("getInvoices", this.selectedClientEvent._id);
      this.$swal(
        "Vorgang beendet",
        "Die Zahlungsvorgänge wurden aktualisiert",
        "success"
      );
    },
    showCancelAlert() {
      this.$swal({
        title: "Wollen Sie alle Zahlungsvorgänge aktualisieren?",
        text: `Dies wird einige Zeit (ca. ${this.estimatedRefreshTime}, je nach Verbindung und Auslastung des Servers) in Anspruch nehmen, schließen Sie dieses Fenster nicht bevor der Vorgang beendet wurde!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ja, jetzt aktualisieren",
        cancelButtonText: "Nein, abbrechen",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.updatePayments();
        }
      });
    },
    showPaymentAlert() {
      this.$swal({
        title: "Rechnung stornieren",
        text: "Möchten Sie die Rechnung wirklich stornieren? Dieser Vorgang kann nicht rückgängig gemacht werden! Es wird eine Rückerstattung bei PayPal angefordert!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ja, Rechnung stornieren",
        cancelButtonText: "Nein, abbrechen",
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((result) => {
        if (result.value) {
          this.dialog = false;
          this.cancelInvoice();
          this.$swal.fire(
            "Storniert!",
            "Die Rechnung sowie zugehörige Tickets wurden storniert! Sofern eine Erstattung möglich ist, wird diese eingeleitet.",
            "success"
          );
        } else if (result.dismiss === this.$swal.DismissReason.cancel) {
          this.$swal.fire(
            "Abgebrochen",
            "Der Vorgang wurde nicht ausgeführt",
            "error"
          );
        }
      });
    },
    // showWarningAlert() {
    //   this.$swal({
    //     title: "Wollen Sie alle Zahlungsvorgänge aktualisieren?",
    //     text: "Dies wird einige Zeit in Anspruch nehmen, schließen Sie dieses Fenster nicht bevor der Vorgang beendet wurde!",
    //     type: "warning",
    //     showCancelButton: true,
    //     customClass: {
    //       confirmButton: "btn bg-gradient-success",
    //       cancelButton: "btn bg-gradient-danger",
    //     },
    //     confirmButtonText: "Ja, aktualisieren.",
    //     cancelButtonText: "Nein, abbrechen",
    //     reverseButtons: true,
    //   }).then((result) => {
    //     if (result.value) {
    //       this.updatePayments()
    //     } else if (
    //       /* Read more about handling dismissals below */
    //       result.dismiss === this.$swal.DismissReason.cancel
    //     ) {
    //       this.$swal.fire(
    //         "Abgebrochen",
    //         "Der Vorgang wurde nicht ausgeführt",
    //         "error"
    //       );
    //     }
    //   });
    // },

    refundValidation(date) {
      let d = new Date();
      d.setDate(d.getDate() - 180);
      return Date.parse(date) < d;
    },
    cancelInvoice() {
      this.$store.dispatch("updateInvoices", {
        id: this.editedItem._id,
        eventId: this.selectedClientEvent._id,
      });
      this.close();
      this.closeCancelation();
    },

    editItem(item) {
      console.log(item);
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    showParticipants(item) {
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.showParticipantsDialog = true;
    },
    close() {
      this.dialog = false;
      this.showParticipantsDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    closeCancelation() {
      this.cancelationDialog = false;
      this.showParticipantsDialog = false;
    },

    closeNoInvoice() {
      this.dialogNoInvoice = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      // });
    },

    downloadInvoiceModal(item) {
      console.log(item);
      if (!item.invoice_nr) {
        this.dialogNoInvoice = true;
      } else {
        this.downloadInvoice(item);
      }
    },

    save() {
      this.updateEventEntry(this.editedItem);
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    // this.getInvoices(this.selectedClientEvent._id);
    this.$store.dispatch("getClientEventInvoices", {
      id: this.$route.params.id,
    });
  },
  computed: {
    estimatedRefreshTime() {
      let invoices = this.clientEventInvoices.filter(
        (invoice) => invoice.state == "approved"
      ).length;
      if (invoices >= 60) {
        return Math.round(invoices / 60).toString() + " Minuten";
      }
      return Math.round(invoices).toString() + " Sekunden";
    },
    formTitle() {
      return "Rechnungsdaten Anmelder";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
