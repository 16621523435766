<template>
  <v-row
    class="justify-center d-flex mx-0 mt-n16 bg-white position-relative border-radius-xl shadow px-0"
  >
    <v-col cols="12" class="mx-2 px-0">
      <v-container fluid class="gradient-home-bg">
        <h1
          class="mx-3 text-h1 font-weight-normal text-center mt-15 text-white"
          style="font-size: 35px"
        >
          Zukünftige Events
        </h1>
        <h2 class="mx-3 text-h3 font-weight-light mb-10 text-center text-white">
          Schau dir an, was wir in Zukunft zu bieten haben!
        </h2>

        <v-row class="justify-center pa-0 ma-0" v-if="events.length > 0">
          <v-container style="max-width: 1400px">
            <v-row
              class="ma-0 pa-0 mb-6"
              justify="center"
              v-if="events.length > 0"
            >
              <v-col
                v-for="(item, i) in events.slice(0, 3)"
                :key="item.title + i"
                lg="4"
                md="6"
                sm="12"
                cols="12"
                class="pt-0"
              >
                <event-card :item="item"></event-card>
              </v-col>
            </v-row>
          </v-container>
        </v-row>

        <v-row justify="center" v-else>
          <div class="mb-5">
            <p
              class="text-body text-center font-weight-light text-white mb-10 mt-5"
            >
              Aktuell sind keine Veranstaltungen verfügbar, schaue später wieder
              vorbei!
            </p>
            <v-row justify="center">
              <v-card>
                <v-img
                  height="100%"
                  width="200px"
                  :src="voidImage"
                  class="img-fluid border-radius-lg"
                  :alt="voidImage"
                />
              </v-card>
            </v-row>
          </div>
        </v-row>
        <v-row justify="center" class="my-15">
          <v-btn
            outlined
            :to="'/events'"
            elevation="0"
            color="#fff"
            class="font-weight-bolder py-4 px-4 ms-2"
            :class="'btn-success'"
            x-large
          >
            Alle Events anzeigen
          </v-btn>
        </v-row>
      </v-container>
    </v-col>
    <v-col cols="12" class="mx-0 px-0" style="max-width: 1400px">
      <v-container>
        <appstores-teaser></appstores-teaser>

        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 text-body"
          style="font-size: 35px"
          v-if="eventNews.length > 0"
        >
          Aktuelle News
        </h2>

        <v-row class="ma-0 pa-0" style="max-width: 1400px" justify="center">
          <v-col
            v-for="(item, i) in eventNews.slice(0, 3)"
            :key="item.title + i"
            lg="4"
            md="6"
            sm="12"
            cols="12"
            class="pt-0 mb-0"
          >
            <news-card :item="item"></news-card>
          </v-col>
        </v-row>
      </v-container>

      <sponsors></sponsors>

      <faq></faq>
    </v-col>
  </v-row>
</template>
<script>
import EventCard from "@/components/Widgets/EventCard.vue";
import NewsCard from "@/components/Widgets/NewsCard.vue";
import Sponsors from "@/components/Widgets/Sponsors.vue";
import faq from "@/components/Widgets/FAQ.vue";
export default {
  name: "Home",
  components: {
    EventCard,
    NewsCard,
    Sponsors,
    faq,
  },
  computed: {
    events() {
      return this.$store.getters.events;
    },
    eventNews() {
      return this.$store.getters.eventNews;
    },
  },
  mounted() {
    this.$store.dispatch("getEvents");
    this.$store.dispatch("getEventNews");
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
  data() {
    return {
      tab: "",
      voidImage: require("@/assets/img/undraw/undraw_void_3ggu.png"),
    };
  },
};
</script>

<style>
.gradient-home-bg {
  background: rgb(226, 49, 45);
  background: linear-gradient(
    90deg,
    rgba(226, 49, 45, 1) 0%,
    rgba(255, 238, 37, 1) 100%
  );
}
</style>
