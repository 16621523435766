<template>
  <div>
    <loading-overlay v-if="updatingClientEvents" :text="''" />
    <v-container fluid class="px-6 py-6" v-if="selectedClientEvent">
      <v-row class="mb-5">
        <v-col cols="12">
          <v-btn
            elevation="0"
            height="43"
            class="font-weight-bold text-uppercase btn-default bg-gradient-primary py-2 px-6 me-2 mb-sm-0 mb-2"
            color="#5e72e4"
            small
            @click="
              $router
                .push(
                  '/admin/administration/events/details/' +
                    selectedClientEvent._id
                )
                .catch(() => {})
            "
            >Event Details</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image w-100">
                <img
                  :src="getImage(selectedClientEvent.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="selectedClientEvent.img"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage:
                    'url(' + getImage(selectedClientEvent.img) + ')',
                }"
              ></div>
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    {{ selectedClientEvent.title }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Eventinformationen
              </h5>
              <v-row>
                <v-col cols="12" lg="4" class="mt-5">
                  <h6
                    class="text-caption text-uppercase font-weight-600 text-muted"
                  >
                    Neues Titelbild hochladen (optional)
                  </h6>
                  <dropzone @newFile="setFile"></dropzone>
                </v-col>
              </v-row>
              <div class="border-bottom my-5"></div>
              <v-form>
                <div class="">
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !selectedClientEvent.title ||
                          selectedClientEvent.title.length < 8
                            ? 'text-danger'
                            : ''
                        "
                        >Eventtitel</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        v-model="selectedClientEvent.title"
                        class="input-style font-size-input text-light-input placeholder-light"
                      >
                      </v-text-field>
                    </v-col>
                    <!-- 
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventkürzel (für Codes)</label
                      >
                      <v-text-field
                        type="email"
                        hide-details
                        outlined
                        v-model="selectedClientEvent.short"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !selectedClientEvent.description ||
                          selectedClientEvent.description.length < 8
                            ? 'text-danger'
                            : ''
                        "
                        >Eventbeschreibung</label
                      >
                      <vue-editor
                        v-model="selectedClientEvent.description"
                      ></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Wichtige Hinweise / AGB (optional)</label
                      >
                      <vue-editor
                        v-model="selectedClientEvent.hints"
                      ></vue-editor>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !selectedClientEvent.slots ||
                          selectedClientEvent.slots < 10
                            ? 'text-danger'
                            : ''
                        "
                        >Anzahl Teilnehmer</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        type="number"
                        v-model="selectedClientEvent.slots"
                      >
                      </v-text-field>

                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        :class="
                          !selectedClientEvent.organizer ||
                          selectedClientEvent.organizer.length < 4
                            ? 'text-danger'
                            : ''
                        "
                        >Veranstalter</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="selectedClientEvent.organizer"
                      >
                      </v-text-field>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        >Website Veranstalter (optional)</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="selectedClientEvent.website"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-card
                        class="pa-4 mt-0 mt-md-7"
                        :class="
                          selectedClientEvent.hide_ticket_amount
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Verbleibende Tickets verbergen
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Verbleibende Tickets werden
                              {{
                                selectedClientEvent.hide_ticket_amount
                                  ? "nicht"
                                  : ""
                              }}
                              angezeigt.
                            </p>
                          </div>

                          <v-switch
                            v-model="selectedClientEvent.hide_ticket_amount"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                      <v-card
                        class="pa-4 mt-0 mt-2"
                        :class="
                          selectedClientEvent.personalization_required
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Personalisierung erzwingen
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Tickets müssen
                              {{
                                selectedClientEvent.personalization_required
                                  ? ""
                                  : "nicht"
                              }}
                              personalisiert werden.
                            </p>
                          </div>

                          <v-switch
                            v-model="
                              selectedClientEvent.personalization_required
                            "
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                      <v-card
                        class="pa-4 mt-2"
                        :class="
                          selectedClientEvent.address_required
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Adressdaten anfordern
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Adressangaben müssen
                              {{
                                selectedClientEvent.address_required
                                  ? ""
                                  : "nicht"
                              }}
                              personalisiert werden.
                            </p>
                          </div>

                          <v-switch
                            v-model="selectedClientEvent.address_required"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6"
                                :class="
                                  !selectedClientEvent.date ? 'text-danger' : ''
                                "
                                >Angezeigtes Datum für den Einlass</label
                              >
                            </div>
                          </div>
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="entry"
                            v-model="selectedClientEvent.date"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <label for="start" class="text-h6"
                            >Event wird angezeigt ab:</label
                          >
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="start"
                            v-model="selectedClientEvent.date_start"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <label for="end" class="text-h6"
                            >Event wird angezeigt bis:</label
                          >
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="end"
                            v-model="selectedClientEvent.date_end"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 text-h6 mb-2 d-block"
                        >Verbundenes Event (Optional)
                      </label>
                      <v-select
                        :items="[
                          { _id: null, title: '- kein Event verbinden -' },
                          ...clientEvents.filter(
                            (el) => el._id != selectedClientEvent._id
                          ),
                        ]"
                        v-model="selectedClientEvent.linkedEvent"
                        hide-details
                        outlined
                        placeholder="Event wählen (optional)"
                        item-value="_id"
                        item-text="title"
                        class="font-size-input input-style text-light-input placeholder-light select-style mb-0"
                        single-line
                        height="28"
                      >
                      </v-select>
                      <p class="mt-5 text-h6">
                        Information zur Verbindung von Events:
                      </p>
                      <p class="mt-2">
                        Wird ein weiteres Event mit diesem verbunden, so ist der
                        Ticket-Scan für das VERBUNDENE Event, auch bei der
                        Auswahl DIESES Events, innerhalb der App möglich.
                      </p>
                      <p class="mt-2">
                        Hinweis: dies funktioniert nur in bei der Auswahl DIESES
                        Events innerhalb der App. Soll das andere Event auch
                        verbunden werden, muss dies dort separat gewählt werden.
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Veranstaltungsort
              </h5>
              <div class="mt-5">
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !selectedClientEvent.street ||
                        selectedClientEvent.street.length < 4
                          ? 'text-danger'
                          : ''
                      "
                      >Straße</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="selectedClientEvent.street"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !selectedClientEvent.zipcode ||
                        selectedClientEvent.zipcode.length < 5
                          ? 'text-danger'
                          : ''
                      "
                      >Postleitzahl</label
                    >
                    <v-text-field
                      type="number"
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="selectedClientEvent.zipcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !selectedClientEvent.city ||
                        selectedClientEvent.city.length < 3
                          ? 'text-danger'
                          : ''
                      "
                      >Ort</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="selectedClientEvent.city"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Land</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      disabled
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Deutschland"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Rechnungsangaben
              </h5>

              <div class="mt-5">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <v-card class="h-100">
                          <!-- <v-card
                            class="px-4 pt-4"
                            :class="
                              freeEvent
                                ? 'bg-gradient-success'
                                : 'bg-gradient-secondary'
                            "
                          >
                            <div class="d-flex">
                              <div>
                                <p
                                  class="mb-0 text-h5 font-weight-light text-white"
                                >
                                  Gratisevent
                                </p>
                              </div>

                              <v-switch
                                v-model="freeEvent"
                                :ripple="true"
                                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                              ></v-switch>
                            </div>
                          </v-card> -->

                          <div v-if="!freeEvent">
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Onlinepreis in €</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="selectedClientEvent.price"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Eintrittspreis Ermäßigt in € (0.00€ = keine
                                Ermäßigung)</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="selectedClientEvent.price_lowered"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Preis Abendkasse in € (0.00€ = Gratis)</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="selectedClientEvent.price_local"
                              >
                              </v-text-field>
                            </v-col>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Ermäßigungshinweise</label
                        >
                        <v-textarea
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light"
                          dense
                          flat
                          outlined
                          height="200"
                          name="input-7-4"
                          style="
                            border-color: rgba(255, 255, 255) !important;
                            border-width: thick !important;
                          "
                          v-model="selectedClientEvent.price_lowered_info"
                          placeholder="Ermäßigungshinweise"
                        ></v-textarea>
                      </v-col>
                      <v-col>
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Rechnungs-Prefix</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          v-model="selectedClientEvent.invoicePrefix"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Umsatzsteuer in %</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          v-model="selectedClientEvent.vat_value"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
          <v-row class="pa-0 ma-0 mt-10">
            <v-col>
              <v-row align="center">
                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  @click="postEventTest()"
                  class="font-weight-600 text-capitalize bg-gradient-success text-white py-3 mr-3 mt-3 px-6 rounded-sm"
                  color="#fff"
                  >Event speichern</v-btn
                >

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  @click="
                    $store.dispatch('getClientEventById', {
                      id: $route.params.id,
                      reset: false,
                    })
                  "
                  class="font-weight-600 text-capitalize text-white bg-gradient-danger py-3 px-6 mt-3 rounded-sm"
                  color="#fff"
                  >Änderungen zurücksetzen</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";

import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Dropzone from "./Widgets/Dropzone.vue";
import administrationData from "@/mixins/administrationMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "EventEdit",
  mixins: [administrationData],
  components: {
    VueEditor,
    DatePicker,
    Dropzone,
  },
  data: function () {
    return {
      freeEvent: false,
    };
  },
  mounted() {
    this.$store.dispatch("getClientEventById", {
      id: this.$route.params.id,
      reset: true,
    });
    if (this.selectedClientEvent) {
      console.log(this.selectedClientEvent.manager);
      this.$store.dispatch("getClientEvents", {
        id: this.selectedClientEvent.manager,
      });
    } else {
      console.log("no event");
    }
  },
  computed: {},
  watch: {
    selectedClientEvent() {
      if (this.selectedClientEvent) {
        console.log(this.selectedClientEvent.manager);
        this.$store.dispatch("getClientEvents", {
          id: this.selectedClientEvent.manager,
        });
      } else {
        console.log("no event");
      }
    },
    event() {
      if (this.event) {
        console.log("event is there");
        if (
          this.selectedClientEvent.price == 0 &&
          this.selectedClientEvent.price_lowered == 0 &&
          this.selectedClientEvent.price_local == 0
        ) {
          console.log("event is there and change");
          this.freeEvent = true;
        }
      }
    },
    freeEvent() {
      if (this.freeEvent) {
        this.selectedClientEvent.price = 0;
        this.selectedClientEvent.price_lowered = 0;
        this.selectedClientEvent.price_local = 0;
      } else {
      }
    },
  },
  methods: {
    setFile(file) {
      console.log("file emitted!");
      this.selectedClientEvent.file = file;
    },
    updateEvent() {
      this.$store.dispatch("updateEvent", this.event);
      this.SnackbarShow("Success");
    },

    postEventTest() {
      console.log("postevent");
      if (
        !this.selectedClientEvent.title ||
        !this.selectedClientEvent.description
      )
        return;
      this.$store.commit("updatingClientEvents", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("description", this.selectedClientEvent.description);
        formData.append("title", this.selectedClientEvent.title);
        formData.append("disclaimer", this.selectedClientEvent.disclaimer);
        formData.append("organizer", this.selectedClientEvent.organizer);
        formData.append("date", new Date(this.selectedClientEvent.date));
        console.log(
          this.selectedClientEvent.linkedEvent,
          "this.selectedClientEvent.linkedEvent"
        );
        formData.append("linkedEvent", this.selectedClientEvent.linkedEvent);

        if (this.selectedClientEvent.date_start) {
          formData.append(
            "date_start",
            new Date(this.selectedClientEvent.date_start)
          );
        }
        if (this.selectedClientEvent.date_end) {
          formData.append(
            "date_end",
            new Date(this.selectedClientEvent.date_end)
          );
        }

        if (this.selectedClientEvent.hide_ticket_amount) {
          formData.append("hide_ticket_amount", true);
        } else {
          formData.append("hide_ticket_amount", false);
        }

        if (this.selectedClientEvent.personalization_required) {
          formData.append("personalization_required", true);
        } else {
          formData.append("personalization_required", false);
        }

        if (this.selectedClientEvent.address_required) {
          formData.append("address_required", true);
        } else {
          formData.append("address_required", false);
        }

        formData.append("slots", this.selectedClientEvent.slots);
        formData.append("hints", this.selectedClientEvent.hints);
        formData.append("price", this.selectedClientEvent.price);
        formData.append(
          "price_lowered",
          this.selectedClientEvent.price_lowered
        );
        formData.append(
          "price_lowered_info",
          this.selectedClientEvent.price_lowered_info
        );
        formData.append("price_local", this.selectedClientEvent.price_local);
        if (this.selectedClientEvent.vat_value) {
          formData.append("vat_value", this.selectedClientEvent.vat_value);
        }

        formData.append("html", this.selectedClientEvent.html);
        formData.append("short", this.selectedClientEvent.short);
        // formData.append("manager", this.userInformations._id);
        formData.append("client_id", this.userInformations.client_id);
        formData.append("street", this.selectedClientEvent.street);
        formData.append("city", this.selectedClientEvent.city);
        formData.append("zipcode", this.selectedClientEvent.zipcode);
        formData.append(
          "invoicePrefix",
          this.selectedClientEvent.invoicePrefix
        );
        formData.append("website", this.selectedClientEvent.website);

        if (this.selectedClientEvent.file) {
          formData.append("image", this.selectedClientEvent.file);
        } else {
          formData.append("img", this.selectedClientEvent.img);
        }

        axiosAuth
          .patch("events/" + this.selectedClientEvent._id, formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updupdatingClientEventsating", false);
            // this.$store.dispatch("getEventsAdmin");
            this.$store.dispatch("getClientEventById", {
              id: this.$route.params.id,
              reset: false,
            });
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Event gespeichert!"
            );
          })
          .catch((error) => {
            this.$store.commit("updatingClientEvents", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            console.log(error.response);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            reject(error.response.data);
          });
      });
    },
  },
};
</script>
