<template>
  <div>
    <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6" v-if="news">
      <v-row class="mb-5">
        <v-col cols="12">
          <v-btn
            elevation="0"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mb-sm-0 mb-2
            "
            color="#5e72e4"
            small
            @click="$router.push('/admin/news/details/' + news._id).catch(() => {})"
            >News Details</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image w-100">
                <img
                  :src="getImage(news.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="news.img"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(news.img) + ')',
                }"
              ></div>
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    {{ news.title }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                News Informationen
              </h5>
              <v-row>
                <v-col cols="12" lg="4" class="mt-5">
                  <h6
                    class="
                      text-caption text-uppercase
                      font-weight-600
                      text-muted
                    "
                  >
                    Neues Titelbild hochladen (optional)
                  </h6>
                  <dropzone @newFile="setFile"></dropzone>
                </v-col>
              </v-row>
              <div class="border-bottom my-5"></div>
              <v-form>
                <div class="ps-lg-5 mt-5">
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Newstitel</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        outlined
                        v-model="news.title"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Urheber- und Fotoinformationen</label
                      >
                      <v-text-field
                        outlined
                        v-model="news.disclaimer"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Newscontent</label
                      >
                      <vue-editor v-model="news.description"></vue-editor>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <v-row class="">
                <v-col>
                  <v-row align="center" justify="space-around">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      :disabled="checkForm"
                      @click="postNewsTest()"
                      class="
                        font-weight-600
                        text-capitalize
                        btn-neutral
                        py-3
                        px-6
                        rounded-sm
                      "
                      color="#fff"
                      >News speichern</v-btn
                    >
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="resetUserData()"
                      class="
                        font-weight-600
                        text-capitalize
                        btn-neutral
                        py-3
                        px-6
                        rounded-sm
                      "
                      color="#fff"
                      >Änderungen zurücksetzen</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";
import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "NewsEdit",
  mixins: [coreData],
  components: {
    VueEditor,
    Dropzone,
  },
  data: function () {
    return {};
  },
  mounted() {},
  computed: {
    checkForm() {
      if (this.news.title && this.news.description) return false;
      return true;
    },
  },
  methods: {
    setFile(file) {
      console.log("file emitted!");
      this.news.file = file;
    },
    updateNews() {
      this.$store.dispatch("updateEventNews", this.news);
      this.SnackbarShow("Success");
    },
    postNewsTest() {
      if (!this.news.title || !this.news.description) return;
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("description", this.news.description);
        formData.append("title", this.news.title);
        formData.append("disclaimer", this.news.disclaimer);
        formData.append("manager", this.userInformations._id);
        if (this.news.file) {
          formData.append("image", this.news.file);
        } else {
          formData.append("img", this.news.img);
        }
        formData.append("date", this.news.date);

        axiosAuth
          .patch("eventnews/" + this.news._id, formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("getEventNewsAdmin");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "News gespeichert!"
            );
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            console.log(error.response);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            reject(error.response.data);
          });
      });
    },
  },
};
</script>
