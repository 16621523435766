<template>
  <div >
     <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6" v-if="news">
      <v-row class="mb-5">
        <v-col cols="12">
          <v-btn
            elevation="0"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mb-sm-0 mb-2
            "
            color="#5e72e4"
            small
            @click="$router.push('/admin/news/edit/' + news._id).catch(() => {})"
            >News bearbeiten</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image" v-if="news.img">
                <img
                  :src="getImage(news.img)"
                  class="shadow border-radius-lg w-100"
                  :alt="news.img"
                />
              </div>
              <div
                v-if="news.img"
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(news.img) + ')',
                }"
              ></div>
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    {{ news.title }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span v-html="news.description"></span>
                </v-col>
              </v-row>
              <p class="text-xs mt-2 font-weight-light text-body ma-0">
                {{ news.created | moment("DD.MM.YYYY HH:mm") }}
              </p>
            </div>
          </v-card>
          <v-row>
            <v-col cols="12" lg="6">
              <v-card
                class="px-4 py-4 card-shadow border-radius-xl h-100"
                :class="
                  news.active ? 'bg-gradient-success' : 'bg-gradient-secondary'
                "
              >
                <div class="d-flex mb-4">
                  <p class="mb-0 text-white font-weight-light">
                    {{ news.active ? " Aktiv" : "Inaktiv" }}
                  </p>
                  <v-switch
                    @change="updateEventNews(news)"
                    :ripple="false"
                    class="d-inline-flex mt-0 pt-0 switch ms-auto"
                    v-model="news.active"
                    hide-details
                  ></v-switch>
                </div>

                <p class="mt-4 mb-0 text-white font-weight-bold">
                  Beitragsstatus
                </p>
                <span class="text-xs text-white">{{
                  news.active
                    ? "Beitrag ist sichtbar"
                    : "Beitrag ist nicht sichtbar"
                }}</span>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="mb-6 card-shadow border-radius-xl py-4 h-100">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-primary border-radius-xl mt-n8"
                      class="shadow-primary"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >leaderboard</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Beitrags Interaktionen
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      {{ news.views }}
                    </h4>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col sm="3" cols="12">
              <v-card class="mb-6 card-shadow border-radius-xl py-4 h-100">
                <v-row no-gutters class="px-4">
                  <v-col sm="4">
                    <v-avatar
                      color="bg-gradient-info border-radius-xl mt-n8"
                      class="shadow-info"
                      height="64"
                      width="64"
                    >
                      <v-icon class="material-icons-round text-white" size="24"
                        >comments</v-icon
                      >
                    </v-avatar>
                  </v-col>
                  <v-col sm="8" class="text-end">
                    <p
                      class="
                        text-sm
                        mb-0
                        text-capitalize text-body
                        font-weight-light
                      "
                    >
                      Kommentare
                    </p>
                    <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                      {{ newsComments.length }}
                    </h4>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mb-5">
            <v-col cols="12">
              <datatable-push-notifications></datatable-push-notifications>
            </v-col>
          </v-row>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <v-row class="mt-0 ml-1" justify="space-between">
                <h5 class="font-weight-bolder text-h5 text-typo mb-10">
                  {{ newsComments.length }} Kommentare
                </h5>
                <div class="mr-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="getNewsComments(news._id)"
                        v-bind="attrs"
                        v-on="on"
                        class="material-icons-round text-primary ms-auto"
                        size="30"
                      >
                        refresh
                      </v-icon>
                    </template>
                    <span>Aktualisieren</span>
                  </v-tooltip>
                </div>
              </v-row>

              <div v-if="newsComments.length == 0">
                <h5 class="text-h5 text-typo font-weight-bold mt-0 mb-2">
                  Keine Kommentare vorhanden
                </h5>
              </div>
              <div v-else>
               
                <div class="d-flex">
                  <div class="flex-grow-1 ms-4">
                    <div :key="index" v-for="(comment, index) in newsComments">
                      <v-list-item
                        :ripple="false"
                        class="px-0 border-radius-sm mb-2"
                      >
                        <v-avatar
                          width="48"
                          height="48"
                          class="shadow border-radius-lg me-4"
                        >
                          <img
                            v-if="comment.user.avatar"
                            :src="getImage(comment.user.avatar) "
                            alt="Avatar"
                            class="border-radius-lg"
                          />
                          <div v-else>
                            {{ comment.user.username.charAt(0)
                            }}{{ comment.user.username.charAt(1) }}
                          </div>
                        </v-avatar>
                        <v-list-item-content>
                          <div class="d-flex align-center">
                            <div>
                              <h6
                                class="
                                  mb-0
                                  text-sm text-sm text-typo
                                  font-weight-bold
                                "
                              >
                                {{ comment.user.username }}
                              </h6>
                              <p
                                class="mb-0 text-xs text-body font-weight-light"
                              >
                                {{ comment.comment }}
                              </p>
                            </div>
                            <div class="ms-auto">
                              <v-btn
                                @click="showDeleteDialog(comment)"
                                :ripple="false"
                                small
                                text
                                width="auto"
                                class="
                                  pe-4
                                  ps-0
                                  mb-0
                                  text-primary
                                  font-weight-bolder
                                "
                              >
                                Löschen
                              </v-btn>
                            </div>
                          </div>
                        </v-list-item-content>
                      </v-list-item>
                      <p class="text-xs mt-2 font-weight-light text-body">
                        {{ comment.created | moment("DD.MM.YYYY HH:mm") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600 pt-5"
              >Aktion erfolgreich!</span
            >
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    <delete-dialog :open="dialogDelete" @close="closeCommentDelete" @confirm="deleteCommentConfirm"></delete-dialog>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";

import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import DatatablePushNotifications from "./Widgets/DatatablePushNotifications.vue";
import DeleteDialog from '@/components/DeleteDialog.vue'
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "NewsDetails",
  mixins: [coreData],
  components: {
    DeleteDialog,
    VueEditor,
    Dropzone,
    DatatablePushNotifications,
  },
  data: function () {
    return {
      dialogDelete: false,
      dialogDeleteComment: false,
      editedItemDelete: {}
    };
  },
  watch: {
    news() {
      if (this.news) {
        this.getNewsComments(this.news._id);
      }
    },
  },
  computed: {},
  methods: {
    updateEventNews() {
      this.$store.dispatch("updateEventNews", this.news);
      this.SnackbarShow("Success");
    },
    showDeleteDialog(item){
      this.editedItemDelete = item
      this.dialogDelete = true
    },

    showDeleteCommentDialog(item){
      this.editedCommentDelete = item
      this.dialogDelete = true
    },

    deleteCommentConfirm() {
      this.deleteComment()
    },

    closeCommentDelete(){
      this.editedItemDelete = null;
      this.dialogDelete = false;
    },

    deleteComment() {
      if(!this.editedItemDelete) return;
      axiosAuth
        .delete("newsComments/" + this.editedItemDelete._id)
        .then(() => {
          this.uploadComplete = true;
          this.$store.commit("updating", false);
          this.$store.dispatch("getNewsComments", this.news._id);
          this.SnackbarShow(
            "Success",
            "Aktion erfolgreich!",
            "Kommentar erfolgreich gelöscht!"
          );
          this.closeCommentDelete();
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("updating", false);
          this.SnackbarShow("Danger", "Fehler!");
        });
    },
  },
};
</script>
