<template>
  <div>
    <loading-overlay v-if="updating || updatingEvents" :text="''" />
    <v-container fluid class="px-6 py-6" v-if="event">
      <v-card
        v-if="!userInformations.vatNumber && !userInformations.vatMessage"
        class="card-shadow border-radius-xl text-center mt-5 bg-danger mb-10"
      >
        <h5 class="font-weight-bolder text-h5 text-white pt-5 mb-0">
          Umsatzsteuer-ID / Umsatzsteuer Hinweis (Verein,
          Kleinunternehmer-Regelung, ...) fehlt ! Bitte im Profil ergänzen!
        </h5>
        <v-row class="ma-0 pa-0 text-center" align="center">
          <v-col>
            <v-btn
              elevation="0"
              height="43"
              class="font-weight-bold text-uppercase btn-default bg-gradient-secondary py-2 px-6 me-2 mb-sm-0 mb-2"
              color="#5e72e4"
              small
              @click="$router.push('/admin/profile').catch(() => {})"
              >Zum Profil</v-btn
            >
          </v-col>
        </v-row>
      </v-card>

      <v-row class="mb-5">
        <v-col cols="12">
          <v-btn
            elevation="0"
            height="43"
            class="font-weight-bold text-uppercase btn-default bg-gradient-primary py-2 px-6 me-2 mb-sm-0 mb-2"
            color="#5e72e4"
            small
            @click="
              $router.push('/admin/events/details/' + event._id).catch(() => {})
            "
            >Event Details</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image w-100">
                <img
                  :src="getImage(event.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="event.img"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(event.img) + ')',
                }"
              ></div>
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    {{ event.title }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Eventinformationen
              </h5>
              <v-row>
                <v-col cols="12" lg="4" class="mt-5">
                  <h6
                    class="text-caption text-uppercase font-weight-600 text-muted"
                  >
                    Neues Titelbild hochladen (optional)
                  </h6>
                  <dropzone @newFile="setFile"></dropzone>
                </v-col>
              </v-row>
              <div class="border-bottom my-5"></div>
              <v-form>
                <div class="">
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !event.title || event.title.length < 8
                            ? 'text-danger'
                            : ''
                        "
                        >Eventtitel</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        v-model="event.title"
                        class="input-style font-size-input text-light-input placeholder-light"
                      >
                      </v-text-field>
                    </v-col>
                    <!-- 
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventkürzel (für Codes)</label
                      >
                      <v-text-field
                        type="email"
                        hide-details
                        outlined
                        v-model="event.short"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !event.description || event.description.length < 8
                            ? 'text-danger'
                            : ''
                        "
                        >Eventbeschreibung</label
                      >
                      <vue-editor v-model="event.description"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Wichtige Hinweise / AGB (optional)</label
                      >
                      <vue-editor v-model="event.hints"></vue-editor>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !event.slots || event.slots < 10 ? 'text-danger' : ''
                        "
                        >Anzahl Teilnehmer</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        type="number"
                        v-model="event.slots"
                      >
                      </v-text-field>

                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        :class="
                          !event.organizer || event.organizer.length < 4
                            ? 'text-danger'
                            : ''
                        "
                        >Veranstalter</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="event.organizer"
                      >
                      </v-text-field>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        >Website Veranstalter (optional)</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="event.website"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-card
                        class="pa-4 mt-0 mt-md-7"
                        :class="
                          event.hide_ticket_amount
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Verbleibende Tickets verbergen
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Verbleibende Tickets werden
                              {{ event.hide_ticket_amount ? "nicht" : "" }}
                              angezeigt.
                            </p>
                          </div>

                          <v-switch
                            v-model="event.hide_ticket_amount"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                      <v-card
                        class="pa-4 mt-0 mt-2"
                        :class="
                          event.personalization_required
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Personalisierung erzwingen
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Tickets müssen
                              {{
                                event.personalization_required ? "" : "nicht"
                              }}
                              personalisiert werden.
                            </p>
                          </div>

                          <v-switch
                            v-model="event.personalization_required"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                      <v-card
                        class="pa-4 mt-2"
                        :class="
                          event.address_required
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Adressdaten anfordern
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Adressangaben müssen
                              {{ event.address_required ? "" : "nicht" }}
                              personalisiert werden.
                            </p>
                          </div>

                          <v-switch
                            v-model="event.address_required"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-0 mt-5">
                    Einlass / Beginn
                  </h5>
                  <v-row>
                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6"
                                :class="!event.date_inlet ? 'text-danger' : ''"
                                >Angezeigtes Datum für den Einlass</label
                              >
                            </div>
                          </div>
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="entry"
                            v-model="event.date_inlet"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!event.date_inlet"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Einlasszeit wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6"
                                :class="!event.date ? 'text-danger' : ''"
                                >Angezeigtes Datum für den Beginn</label
                              >
                            </div>
                          </div>
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="entry"
                            v-model="event.date"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!event.date"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Beginn wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-0 my-5">
                    Sichtbarkeit / Anzeigedatum
                  </h5>
                  <v-row>
                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <label
                            for="start"
                            class="text-h6"
                            :class="!event.date_start ? 'text-danger' : ''"
                            >Event wird angezeigt ab:</label
                          >
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="start"
                            v-model="event.date_start"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!event.date_start"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Anzeige ab wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <label
                            for="end"
                            class="text-h6"
                            :class="!event.date_end ? 'text-danger' : ''"
                            >Event wird angezeigt bis:</label
                          >
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="end"
                            v-model="event.date_end"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!event.date_end"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Anzeige bis wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 text-h6 mb-2 d-block"
                        >Verbundenes Event (Optional)
                      </label>
                      <v-select
                        :items="[
                          { _id: null, title: '- kein Event verbinden -' },
                          ...events.filter((el) => el._id != event._id),
                        ]"
                        v-model="event.linkedEvent"
                        hide-details
                        outlined
                        placeholder="Event wählen (optional)"
                        item-value="_id"
                        item-text="title"
                        class="font-size-input input-style text-light-input placeholder-light select-style mb-0"
                        single-line
                        height="28"
                      >
                      </v-select>
                      <p class="mt-5 text-h6">
                        Information zur Verbindung von Events:
                      </p>
                      <p class="mt-2">
                        Wird ein weiteres Event mit diesem verbunden, so ist der
                        Ticket-Scan für das VERBUNDENE Event, auch bei der
                        Auswahl DIESES Events, innerhalb der App möglich.
                      </p>
                      <p class="mt-2">
                        Hinweis: dies funktioniert nur in bei der Auswahl DIESES
                        Events innerhalb der App. Soll das andere Event auch
                        verbunden werden, muss dies dort separat gewählt werden.
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Veranstaltungsort
              </h5>
              <div class="mt-5">
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !event.street || event.street.length < 4
                          ? 'text-danger'
                          : ''
                      "
                      >Straße</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="event.street"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !event.zipcode || event.zipcode.length < 5
                          ? 'text-danger'
                          : ''
                      "
                      >Postleitzahl</label
                    >
                    <v-text-field
                      type="number"
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="event.zipcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !event.city || event.city.length < 3
                          ? 'text-danger'
                          : ''
                      "
                      >Ort</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="event.city"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Land</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      disabled
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Deutschland"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !event.venue || event.venue.length < 4
                          ? 'text-danger'
                          : ''
                      "
                      >Veranstaltungsstätte</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="event.venue"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Rechnungsangaben
              </h5>

              <div class="mt-5">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <v-card class="h-100">
                          <!-- <v-card
                            class="px-4 pt-4"
                            :class="
                              freeEvent
                                ? 'bg-gradient-success'
                                : 'bg-gradient-secondary'
                            "
                          >
                            <div class="d-flex">
                              <div>
                                <p
                                  class="mb-0 text-h5 font-weight-light text-white"
                                >
                                  Gratisevent
                                </p>
                              </div>

                              <v-switch
                                v-model="freeEvent"
                                :ripple="true"
                                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                              ></v-switch>
                            </div>
                          </v-card> -->

                          <div v-if="!freeEvent">
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Onlinepreis in €</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="event.price"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Eintrittspreis Ermäßigt in € (0.00€ = keine
                                Ermäßigung)</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="event.price_lowered"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Preis Abendkasse in € (0.00€ = Gratis)</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="event.price_local"
                              >
                              </v-text-field>
                            </v-col>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Ermäßigungshinweise</label
                        >
                        <v-textarea
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light"
                          dense
                          flat
                          outlined
                          height="200"
                          name="input-7-4"
                          style="
                            border-color: rgba(255, 255, 255) !important;
                            border-width: thick !important;
                          "
                          v-model="event.price_lowered_info"
                          placeholder="Ermäßigungshinweise"
                        ></v-textarea>
                      </v-col>
                      <v-col>
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Rechnungs-Prefix</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          v-model="event.invoicePrefix"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col v-if="userInformations.vatNumber">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Umsatzsteuer in %</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          v-model="event.vat_value"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col v-else>
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Umsatzsteuer in %</label
                        >
                        <p v-if="userInformations.vatMessage">
                          {{ userInformations.vatMessage }}
                        </p>
                        <p class="text-danger" v-else>
                          Umsatzsteuer-ID / Umsatzsteuer Hinweis (Verein,
                          Kleinunternehmer-Regelung, ...) fehlt ! Bitte im
                          Profil ergänzen!
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            v-if="!userInformations.vatNumber && !userInformations.vatMessage"
            class="card-shadow border-radius-xl text-center mt-5 bg-danger mb-10"
          >
            <h5 class="font-weight-bolder text-h5 text-white pt-5 mb-0">
              Umsatzsteuer-ID / Umsatzsteuer Hinweis (Verein,
              Kleinunternehmer-Regelung, ...) fehlt ! Bitte im Profil ergänzen!
            </h5>
            <v-row class="ma-0 pa-0 text-center" align="center">
              <v-col>
                <v-btn
                  elevation="0"
                  height="43"
                  class="font-weight-bold text-uppercase btn-default bg-gradient-secondary py-2 px-6 me-2 mb-sm-0 mb-2"
                  color="#5e72e4"
                  small
                  @click="$router.push('/admin/profile').catch(() => {})"
                  >Zum Profil</v-btn
                >
              </v-col>
            </v-row>
          </v-card>

          <v-row class="pa-0 ma-0 mt-10">
            <v-col>
              <v-row align="center">
                <v-btn
                  elevation="0"
                  :ripple="false"
                  :disabled="checkForm"
                  height="43"
                  @click="postEventTest()"
                  class="font-weight-600 text-capitalize bg-gradient-success text-white py-3 mr-3 mt-3 px-6 rounded-sm"
                  :color="
                    !checkForm ? 'bg-gradient-success' : 'bg-gradient-light'
                  "
                  >Event speichern</v-btn
                >
                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  @click="$store.dispatch('getEventsAdmin')"
                  class="font-weight-600 text-capitalize text-white bg-gradient-danger py-3 px-6 mt-3 rounded-sm"
                  color="#fff"
                  >Änderungen zurücksetzen</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";

import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "EventEdit",
  mixins: [coreData],
  components: {
    VueEditor,
    DatePicker,
    Dropzone,
  },
  data: function () {
    return {
      freeEvent: false,
    };
  },
  mounted() {
    if (this.events.length == 0) {
      this.$store.dispatch("getEventsAdmin");
      console.log(this.events);
    }
  },
  computed: {
    checkForm() {
      if (
        this.event.title &&
        this.event.description &&
        this.event.date &&
        this.event.date_start &&
        this.event.date_end &&
        this.event.organizer &&
        this.event.price &&
        this.event.street &&
        this.event.city &&
        this.event.zipcode &&
        this.event.slots &&
        this.event.invoicePrefix &&
        this.event.venue &&
        this.event.date_inlet &&
        (this.userInformations.vatNumber || this.userInformations.vatMessage)
      )
        return false;
      return true;
    },
  },
  watch: {
    event() {
      if (this.event) {
        console.log("event is there");
        if (
          this.event.price == 0 &&
          this.event.price_lowered == 0 &&
          this.event.price_local == 0
        ) {
          console.log("event is there and change");
          this.freeEvent = true;
        }
      }
    },
    freeEvent() {
      if (this.freeEvent) {
        this.event.price = 0;
        this.event.price_lowered = 0;
        this.event.price_local = 0;
      } else {
      }
    },
  },
  methods: {
    setFile(file) {
      console.log("file emitted!");
      this.event.file = file;
    },
    updateEvent() {
      this.$store.dispatch("updateEvent", this.event);
      this.SnackbarShow("Success");
    },

    postEventTest() {
      console.log("postevent");
      if (!this.event.title || !this.event.description) return;
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("description", this.event.description);
        formData.append("title", this.event.title);
        formData.append("disclaimer", this.event.disclaimer);
        formData.append("organizer", this.event.organizer);
        formData.append("date", new Date(this.event.date));
        console.log(this.event.linkedEvent, "this.event.linkedEvent");
        formData.append("linkedEvent", this.event.linkedEvent);

        if (this.event.date_start) {
          formData.append("date_start", new Date(this.event.date_start));
        }
        if (this.event.date_end) {
          formData.append("date_end", new Date(this.event.date_end));
        }
        if (this.event.date_inlet) {
          formData.append("date_inlet", new Date(this.event.date_inlet));
        }

        if (this.event.hide_ticket_amount) {
          formData.append("hide_ticket_amount", true);
        } else {
          formData.append("hide_ticket_amount", false);
        }

        if (this.event.personalization_required) {
          formData.append("personalization_required", true);
        } else {
          formData.append("personalization_required", false);
        }

        if (this.event.address_required) {
          formData.append("address_required", true);
        } else {
          formData.append("address_required", false);
        }

        formData.append("slots", this.event.slots);
        formData.append("hints", this.event.hints);
        formData.append("price", this.event.price);
        formData.append("price_lowered", this.event.price_lowered);
        formData.append("price_lowered_info", this.event.price_lowered_info);
        formData.append("price_local", this.event.price_local);
        if (this.event.vat_value) {
          formData.append("vat_value", this.event.vat_value);
        }

        formData.append("html", this.event.html);
        // formData.append(
        //   "sponsors",
        //   JSON.stringify(
        //     this.event.sponsors.filter((sponsor) => sponsor.title)
        //   )
        // );
        formData.append("short", this.event.short);
        formData.append("manager", this.userInformations._id);
        formData.append("client_id", this.userInformations.client_id);
        formData.append("street", this.event.street);
        formData.append("city", this.event.city);
        if (this.event.venue) formData.append("venue", this.event.venue);
        formData.append("zipcode", this.event.zipcode);
        formData.append("invoicePrefix", this.event.invoicePrefix);
        formData.append("website", this.event.website);

        if (this.event.file) {
          formData.append("image", this.event.file);
        } else {
          formData.append("img", this.event.img);
        }

        axiosAuth
          .patch("events/" + this.event._id, formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("getEventsAdmin");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Event gespeichert!"
            );
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            console.log(error.response);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            reject(error.response.data);
          });
      });
    },
  },
};
</script>
