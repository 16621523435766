<template>
  <v-row
    class="
      justify-center
      d-flex
      mx-0
      mt-n16
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-col cols="12" >
      <v-container fluid class="py-6" >
        <v-row v-if="userInformations">
          <v-col cols="auto" >
            <v-avatar
              style="cursor: pointer"
              width="74"
              height="74"
              class="shadow border-radius-lg"
              @click="uploadImageDialog = true"
            >
              <img
                v-if="userInformations.avatar"
                :src="getImage(userInformations.avatar)"
                alt="Avatar"
                class="border-radius-lg"
              />
              <div v-else>
                {{ userInformations.firstname.charAt(0)
                }}{{ userInformations.lastname.charAt(0) }}
              </div>
            </v-avatar>
          </v-col>
          <v-col cols="auto" class="my-auto">
            <div class="h-100 pa-3">
              <h5 class="mb-1 text-h5 text-typo font-weight-bold">
                {{ userInformations.firstname }}
                {{ userInformations.lastname }}
              </h5>
              <p class="mb-0 font-weight-light text-body text-sm">
                {{ userInformations.companyName }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="position-relative" v-if="computedTickets[0]">
            <v-row justify="start">
              <v-col
                v-for="item in computedTickets[0].participants"
              :key="item.title"
                lg="4"
                md="4"
                sm="12"
                cols="12"
                class="pt-0 mb-0"
              >
                <ticket-card-q-r :item="item"></ticket-card-q-r>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
import TicketCardQR from "@/components/Widgets/TicketCardQR.vue";
export default {
  name: "TicketDetails",
  components: {
    TicketCardQR,
  },
  data: function () {
    return {};
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
    eventEntries() {
      return this.$store.getters.eventEntries;
    },
    computedTickets() {
      var array = []
      if(this.eventEntries){
        this.eventEntries.filter(entry => entry.event._id == this.$route.params.id).forEach(element => {
          var entryCheck = array.find(entry => entry.event._id == element.event._id)
          if(entryCheck){
            entryCheck.participants.push({_id: element._id, participant_data: element.participant_data, event: element.event, status: element.redeemed})
          }else{
            var newEntry = JSON.parse(JSON.stringify(element))
            newEntry.participants.push({_id: element._id, participant_data: element.participant_data, event: element.event, status: element.redeemed})
            array.push(newEntry)
          }
          
        });
      }
      return array
    },
  },
  mounted() {
    console.log(this.$route.params.id)
    this.$store.dispatch("tryAutoLogin");
    if(this.$route.query.user){
      this.$store.dispatch("getEventEntriesUser", {user: this.$route.query.user, event: this.$route.params.id});
    }else{
      this.$store.dispatch("getEventEntriesUser");
    }
   
  },
  methods: {
    updateUserData() {
      this.$store.dispatch("updateUserInformations", this.userInformations);
      this.SnackbarShow(
        "Success",
        "Aktion erfolgreich!",
        "Profil gespeichert!"
      );
    },
    getImage(link) {
            return process.env.VUE_APP_API_BASEURL + '/' + link
        },
  },
};
</script>
