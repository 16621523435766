<template>
  <v-hover v-slot="{ hover }" open-delay="200">
    <v-card
      :loading="loading"
      class="mx-auto mt-5 rounded-xl"
      :class="{ 'on-hover': hover }"
      width="100%"
      :elevation="hover ? 16 : 2"
      :to="'/events/' + (item.url ? item.url : item._id)"
      style="border-radius: 20px !important"
    >
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <div class="" style="position: relative">
        <v-img
          class="mt-3 mt-sm-0"
          style="border-top-left-radius: 20px; border-top-right-radius: 20px"
          cover
          height="250"
          :src="getImage(item.img)"
        ></v-img>
        <div
          class="border-radius-xl dark--text blur px-2 shadow-blur bg-dark theme--light"
          style="position: absolute; bottom: 15px; left: 15px; font-size: 25px"
        >
          {{ item.date | moment("HH:mm") }} Uhr
        </div>
      </div>
      <!-- <v-img cover height="250" :src="getImage(item.img)"></v-img> -->

      <v-card-text>
        <v-row class="ma-0 pa-0 text-dark">
          <div style="width: 60px">
            <div class="text-h2 font-weight-bold" style="height: 40px">
              {{ item.date | moment("DD") }}
            </div>
            <div class="body-1 font-weight-bold text-warning">
              {{ formatMonth }}
            </div>
          </div>

          <v-col class="pa-0 ma-0">
            <div class="pl-0 mt-2">
              <div class="text-h5 font-weight-bold">
                {{ item.title }}
              </div>
              <!-- <div class="body-1 font-weight-bold">
                {{ calcSlots }} / {{ item.slots }} • Tickets
              </div> -->
              <div
                v-if="!item.hide_ticket_amount && calcSlots > 0"
                class="body-1 font-weight-bold"
              >
                {{ calcSlots }} / {{ item.slots }} • Tickets
              </div>
              <div
                v-else
                class="body-1 font-weight-bold"
                :class="
                  calcSlots > 0
                    ? calcSlots > 20
                      ? 'text-success'
                      : 'text-warning'
                    : 'text-danger'
                "
              >
                {{
                  calcSlots > 0
                    ? calcSlots > 20
                      ? "Tickets verfügbar"
                      : "Nur noch wenige Tickets verfügbar"
                    : "ausgebucht"
                }}
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <div class="grey--text">
        {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
      </div>
      <div class="mt-4 text-subtitle-1">
        {{ item.city }} • {{ item.price > 0 ? 'Online: ' + parseFloat(item.price).toFixed(2) + "€" : "Gratis" }}{{ item.price_lowered > 0 ? ' • Erm.: ' + parseFloat(item.price_lowered).toFixed(2)  + "€" : "" }} {{ item.price_local > 0 ? ' • Abendk.: ' + parseFloat(item.price_local).toFixed(2) + "€" : "" }} {{ item.seat ? ' • Sitzplätze' : ' • Platzart siehe Beschreibung'}}
      </div> -->
      </v-card-text>

      <v-divider class="mx-4"></v-divider>
    </v-card>
  </v-hover>
</template>


<script>
import moment from "moment";
export default {
  name: "EventCard",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    formatMonth() {
      return moment(this.item.date).format("MMMM").toUpperCase().slice(0, 3);
    },
    calcSlots() {
      try {
        return this.item.slots - this.item.entries.length <= 0
          ? 0
          : this.item.slots - this.item.entries.length;
      } catch (error) {
        return 0;
      }
    },
    bookable() {
      if (this.calcSlots > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
.v-card {
  transition: all 0.4s ease-in;
}
</style>