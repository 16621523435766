<template>
  <div>
    <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6">
      <v-card
        v-if="!userInformations.vatNumber && !userInformations.vatMessage"
        class="card-shadow border-radius-xl text-center mt-5 bg-danger mb-10"
      >
        <h5 class="font-weight-bolder text-h5 text-white pt-5 mb-0">
          Umsatzsteuer-ID / Umsatzsteuer Hinweis (Verein,
          Kleinunternehmer-Regelung, ...) fehlt ! Bitte im Profil ergänzen!
        </h5>
        <v-row class="ma-0 pa-0 text-center" align="center">
          <v-col>
            <v-btn
              elevation="0"
              height="43"
              class="font-weight-bold text-uppercase btn-default bg-gradient-secondary py-2 px-6 me-2 mb-sm-0 mb-2"
              color="#5e72e4"
              small
              @click="$router.push('/admin/profile').catch(() => {})"
              >Zum Profil</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <dropzone @newFile="setFile"></dropzone>
              </div>
              <!-- <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(newEvent.img) + ')',
                }"
              ></div> -->
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <h5
                    class="font-weight-bolder text-h5 mb-0"
                    :class="!newEvent.file ? 'text-danger' : 'text-typo'"
                  >
                    Titelbild hochladen
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Event Informationen
              </h5>
              <v-form lazy-validation>
                <div class="ps-lg-5 mt-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !newEvent.title || newEvent.title.length < 8
                            ? 'text-danger'
                            : ''
                        "
                        >Eventtitel (min. 8 Zeichen)</label
                      >
                      <v-text-field
                        required
                        outlined
                        v-model="newEvent.title"
                        class="input-style font-size-input text-light-input placeholder-light"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !newEvent.description ||
                          newEvent.description.length < 8
                            ? 'text-danger'
                            : ''
                        "
                        >Eventbeschreibung</label
                      >
                      <vue-editor v-model="newEvent.description"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Wichtige Hinweise (optional)</label
                      >
                      <vue-editor v-model="newEvent.hints"></vue-editor>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        :class="
                          !newEvent.slots || newEvent.slots < 10
                            ? 'text-danger'
                            : ''
                        "
                        >Anzahl Teilnehmer (min 10)</label
                      >
                      <v-text-field
                        required
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        type="number"
                        v-model="newEvent.slots"
                      >
                      </v-text-field>

                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        :class="
                          !newEvent.organizer || newEvent.organizer.length < 10
                            ? 'text-danger'
                            : ''
                        "
                        >Veranstalter</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="newEvent.organizer"
                      >
                      </v-text-field>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        >Website Veranstalter (optional)</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="input-style font-size-input text-light-input placeholder-light"
                        v-model="newEvent.website"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-card
                        class="pa-4 mt-0 mt-md-7"
                        :class="
                          newEvent.hide_ticket_amount
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Verbleibende Tickets verbergen
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Verbleibende Tickets werden
                              {{ newEvent.hide_ticket_amount ? "nicht" : "" }}
                              angezeigt.
                            </p>
                          </div>

                          <v-switch
                            v-model="newEvent.hide_ticket_amount"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                      <v-card
                        class="pa-4 mt-0 mt-2"
                        :class="
                          newEvent.personalization_required
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Personalisierung erzwingen
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Tickets müssen
                              {{
                                newEvent.personalization_required ? "" : "nicht"
                              }}
                              personalisiert werden.
                            </p>
                          </div>

                          <v-switch
                            v-model="newEvent.personalization_required"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                      <v-card
                        class="pa-4 mt-2"
                        :class="
                          newEvent.address_required
                            ? 'bg-gradient-success'
                            : 'bg-gradient-secondary'
                        "
                      >
                        <div class="d-flex">
                          <div>
                            <p
                              class="mb-0 text-h5 font-weight-light text-white"
                            >
                              Adressdaten anfordern
                            </p>
                            <p class="mb-0 font-weight-light text-white">
                              Adressangaben müssen
                              {{ newEvent.address_required ? "" : "nicht" }}
                              personalisiert werden.
                            </p>
                          </div>

                          <v-switch
                            v-model="newEvent.address_required"
                            :ripple="true"
                            class="d-inline-flex mt-0 pt-0 switch ms-auto"
                          ></v-switch>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-0 mt-5">
                    Einlass / Beginn
                  </h5>
                  <v-row>
                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6"
                                :class="
                                  !newEvent.date_inlet ? 'text-danger' : ''
                                "
                                >Angezeigtes Datum für den Einlass</label
                              >
                            </div>
                          </div>
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="entry"
                            v-model="newEvent.date_inlet"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!newEvent.date_inlet"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Einlasszeit wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>

                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6"
                                :class="!newEvent.date ? 'text-danger' : ''"
                                >Angezeigtes Datum für den Beginn</label
                              >
                            </div>
                          </div>
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="entry"
                            v-model="newEvent.date"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!newEvent.date"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Beginn wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-0 my-5">
                    Sichtbarkeit / Anzeigedatum
                  </h5>
                  <v-row>
                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <label
                            for="start"
                            class="text-h6"
                            :class="!newEvent.date_start ? 'text-danger' : ''"
                            >Event wird angezeigt ab:</label
                          >
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="start"
                            v-model="newEvent.date_start"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!newEvent.date_start"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Anzeige ab wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="6" xl="4">
                      <v-card class="pa-4 mt-2 bg-gradient-light">
                        <v-row justify="center" class="mt-1 mb-3">
                          <label
                            for="end"
                            class="text-h6"
                            :class="!newEvent.date_end ? 'text-danger' : ''"
                            >Event wird angezeigt bis:</label
                          >
                        </v-row>
                        <v-row justify="center" class="mb-4">
                          <date-picker
                            id="end"
                            v-model="newEvent.date_end"
                            mode="dateTime"
                            is24hr
                            :min-date="new Date()"
                          />
                        </v-row>
                        <v-row
                          justify="center"
                          class="mt-1 mb-3"
                          v-if="!newEvent.date_end"
                        >
                          <div>
                            <div>
                              <label
                                for="datepicker"
                                class="text-h6 text-danger"
                                >Anzeige bis wird benötigt!</label
                              >
                            </div>
                          </div>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 text-h6 mb-2 d-block"
                        >Verbundenes Event (Optional)
                      </label>
                      <v-select
                        :items="[
                          { _id: null, title: '- kein Event verbinden -' },
                          ...events.filter((el) => el._id != event._id),
                        ]"
                        v-model="newEvent.linkedEvent"
                        hide-details
                        outlined
                        placeholder="Event wählen (optional)"
                        item-value="_id"
                        item-text="title"
                        class="font-size-input input-style text-light-input placeholder-light select-style mb-0"
                        single-line
                        height="28"
                      >
                      </v-select>
                      <p class="mt-5 text-h6">
                        Information zur Verbindung von Events:
                      </p>
                      <p class="mt-2">
                        Wird ein weiteres Event mit diesem verbunden, so ist der
                        Ticket-Scan für das VERBUNDENE Event, auch bei der
                        Auswahl DIESES Events, innerhalb der App möglich.
                      </p>
                      <p class="mt-2">
                        Hinweis: dies funktioniert nur in bei der Auswahl DIESES
                        Events innerhalb der App. Soll das andere Event auch
                        verbunden werden, muss dies dort separat gewählt werden.
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Veranstaltungsort
              </h5>
              <div class="mt-5">
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !newEvent.street || newEvent.street.length < 4
                          ? 'text-danger'
                          : ''
                      "
                      >Straße</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="newEvent.street"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !newEvent.zipcode || newEvent.zipcode.length < 5
                          ? 'text-danger'
                          : ''
                      "
                      >Postleitzahl</label
                    >
                    <v-text-field
                      type="number"
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="newEvent.zipcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !newEvent.city || newEvent.city.length < 4
                          ? 'text-danger'
                          : ''
                      "
                      >Ort</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="newEvent.city"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Land</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      disabled
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Deutschland"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      :class="
                        !newEvent.venue || newEvent.street.venue < 4
                          ? 'text-danger'
                          : ''
                      "
                      >Veranstaltungsstätte</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      v-model="newEvent.venue"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Rechnungsangaben
              </h5>

              <div class="mt-5">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <v-card class="h-100">
                          <!-- <v-card
                            class="px-4 pt-4"
                            :class="
                              freeEvent
                                ? 'bg-gradient-success'
                                : 'bg-gradient-secondary'
                            "
                          >
                            <div class="d-flex">
                              <div>
                                <p
                                  class="mb-0 text-h5 font-weight-light text-white"
                                >
                                  Gratisevent
                                </p>
                              </div>

                              <v-switch
                                v-model="freeEvent"
                                :ripple="true"
                                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                              ></v-switch>
                            </div>
                          </v-card> -->

                          <div v-if="!freeEvent">
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                :class="
                                  !freeEvent && newEvent.price < 1
                                    ? 'text-danger'
                                    : ''
                                "
                                >Onlinepreis in €</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="newEvent.price"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Eintrittspreis Ermäßigt in € (0.00€ = keine
                                Ermäßigung)</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="newEvent.price_lowered"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <label
                                for=""
                                class="label-color font-weight-600 mb-2 d-block"
                                >Preis Abendkasse in € (0.00€ = Gratis)</label
                              >
                              <v-text-field
                                hide-details
                                outlined
                                type="number"
                                class="input-style font-size-input text-light-input placeholder-light"
                                placeholder="12.34"
                                v-model="newEvent.price_local"
                              >
                              </v-text-field>
                            </v-col>
                          </div>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Ermäßigungshinweise</label
                        >
                        <v-textarea
                          hide-details
                          class="input-style font-size-input text-light-input placeholder-light"
                          dense
                          flat
                          outlined
                          height="200"
                          name="input-7-4"
                          style="
                            border-color: rgba(255, 255, 255) !important;
                            border-width: thick !important;
                          "
                          v-model="newEvent.price_lowered_info"
                          placeholder="Ermäßigungshinweise"
                        ></v-textarea>
                      </v-col>
                      <v-col>
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          :class="!newEvent.invoicePrefix ? 'text-danger' : ''"
                          >Rechnungs-Prefix</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          v-model="newEvent.invoicePrefix"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col v-if="userInformations.vatNumber">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Umsatzsteuer in %</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          v-model="newEvent.vat_value"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col v-else>
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Umsatzsteuer in %</label
                        >
                        <p v-if="userInformations.vatMessage">
                          {{ userInformations.vatMessage }}
                        </p>
                        <p class="text-danger" v-else>
                          Umsatzsteuer-ID / Umsatzsteuer Hinweis (Verein,
                          Kleinunternehmer-Regelung, ...) fehlt ! Bitte im
                          Profil ergänzen!
                        </p>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-card
            v-if="!userInformations.vatNumber && !userInformations.vatMessage"
            class="card-shadow border-radius-xl text-center mt-5 bg-danger"
          >
            <h5 class="font-weight-bolder text-h5 text-white pt-5 mb-0">
              Umsatzsteuer-ID / Umsatzsteuer Hinweis (Verein,
              Kleinunternehmer-Regelung, ...) fehlt ! Bitte im Profil ergänzen!
            </h5>
            <v-row class="ma-0 pa-0 text-center" align="center">
              <v-col>
                <v-btn
                  elevation="0"
                  height="43"
                  class="font-weight-bold text-uppercase btn-default bg-gradient-secondary py-2 px-6 me-2 mb-sm-0 mb-2"
                  color="#5e72e4"
                  small
                  @click="$router.push('/admin/profile').catch(() => {})"
                  >Zum Profil</v-btn
                >
              </v-col>
            </v-row>
          </v-card>

          <v-row class="pa-0 ma-0 mt-10">
            <v-col>
              <v-row align="center">
                <v-btn
                  elevation="0"
                  height="43"
                  :disabled="checkForm"
                  class="font-weight-bold text-uppercase text-white py-2 px-6 me-2 mb-sm-0 mb-2"
                  :color="
                    !checkForm ? 'bg-gradient-success' : 'bg-gradient-light'
                  "
                  small
                  @click="postEvent"
                  >Event anlegen</v-btn
                >
                <v-btn
                  elevation="0"
                  height="43"
                  class="font-weight-bold text-uppercase btn-default bg-gradient-secondary py-2 px-6 me-2 mb-sm-0 mb-2"
                  color="#5e72e4"
                  small
                  @click="$router.go(-1).catch(() => {})"
                  >Abbrechen</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "NewEvent",
  mixins: [coreData],
  components: {
    VueEditor,
    DatePicker,
    Dropzone,
  },
  data: function () {
    return {
      // rules: {
      //     required: value => !!value || 'Benötigt.',
      //     min: v => v.length >= 8 || 'Min 8 Zeichen',
      //   },
      freeEvent: false,
      newEvent: {
        sponsors: [],
        hint: "",
        html: "",
        price: "0.00",
        price_lowered: "0.00",
        price_local: "0.00",
      },
    };
  },

  mounted() {
    if (this.userInformations) {
      if (this.userInformations.companyName) {
        this.newEvent.organizer = this.userInformations.companyName;
      }
      if (this.userInformations.website) {
        this.newEvent.website = this.userInformations.website;
      }
    }
  },
  watch: {
    freeEvent() {
      if (this.freeEvent) {
        this.newEvent.price = "0.00";
        this.newEvent.price_lowered = "0.00";
        this.newEvent.price_local = "0.00";
      } else {
      }
    },
  },
  computed: {
    checkForm() {
      if (
        this.newEvent.title &&
        this.newEvent.description &&
        this.newEvent.date &&
        this.newEvent.date_start &&
        this.newEvent.date_end &&
        this.newEvent.file &&
        this.newEvent.organizer &&
        this.newEvent.price &&
        this.newEvent.street &&
        this.newEvent.city &&
        this.newEvent.zipcode &&
        this.newEvent.slots &&
        this.newEvent.invoicePrefix &&
        this.newEvent.venue &&
        this.newEvent.date_inlet &&
        (this.userInformations.vatNumber || this.userInformations.vatMessage)
      )
        return false;
      return true;
    },
  },

  methods: {
    setFile(file) {
      console.log("file emitted!");
      this.newEvent.file = file;
    },
    getImage(link) {
      if (!link) return null;
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },

    postEvent() {
      console.log("postevent");
      if (this.checkForm)
        return this.SnackbarShow(
          "Danger",
          "Fehler!",
          "Das Formular ist nicht vollständig!"
        );
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        this.newEvent.sponsors
          .filter((sponsor) => sponsor.title)
          .forEach((sponsor) => {
            sponsor.codes = [];
            for (let index = 0; index < sponsor.slots; index++) {
              var rndCode = require("crypto")
                .randomBytes(64)
                .toString("hex")
                .substr(0, 4);
              var code =
                this.newEvent.short +
                "-" +
                sponsor.title.substr(0, 3) +
                "-" +
                rndCode;
              if (!sponsor.codes.find((code) => code.code == code)) {
                sponsor.codes.push({ code: code });
              } else {
                rndCode = require("crypto")
                  .randomBytes(64)
                  .toString("hex")
                  .substr(0, 4);
                code =
                  this.newEvent.short +
                  "-" +
                  sponsor.title.substr(0, 3) +
                  "-" +
                  rndCode;
                sponsor.codes.push({ code: code });
              }
            }
          });

        let formData = new FormData();
        formData.append("description", this.newEvent.description);
        formData.append("title", this.newEvent.title);
        formData.append("disclaimer", this.newEvent.disclaimer);
        formData.append("organizer", this.newEvent.organizer);
        formData.append("date", new Date(this.newEvent.date));
        formData.append("linkedEvent", this.newEvent.linkedEvent);
        if (this.newEvent.date_start) {
          formData.append("date_start", new Date(this.newEvent.date_start));
        }

        if (this.newEvent.date_end) {
          formData.append("date_end", new Date(this.newEvent.date_end));
        }

        if (this.newEvent.date_inlet) {
          formData.append("date_inlet", new Date(this.newEvent.date_inlet));
        }

        if (this.newEvent.hide_ticket_amount) {
          formData.append("hide_ticket_amount", true);
        } else {
          formData.append("hide_ticket_amount", false);
        }

        if (this.newEvent.personalization_required) {
          formData.append("personalization_required", true);
        } else {
          formData.append("personalization_required", false);
        }

        if (this.newEvent.address_required) {
          formData.append("address_required", true);
        } else {
          formData.append("address_required", false);
        }

        formData.append("slots", this.newEvent.slots);
        formData.append("hints", this.newEvent.hints);
        formData.append("price", this.newEvent.price);
        formData.append("price_lowered", this.newEvent.price_lowered);
        formData.append("price_local", this.newEvent.price_local);
        formData.append("vat_value", this.newEvent.vat_value);
        formData.append("html", this.newEvent.html);
        formData.append(
          "sponsors",
          JSON.stringify(
            this.newEvent.sponsors.filter((sponsor) => sponsor.title)
          )
        );
        formData.append("short", this.newEvent.short);
        formData.append("manager", this.userInformations._id);
        formData.append("client_id", this.userInformations.client_id);
        formData.append("street", this.newEvent.street);
        formData.append("city", this.newEvent.city);
        if (this.newEvent.venue) formData.append("venue", this.newEvent.venue);
        formData.append("zipcode", this.newEvent.zipcode);
        formData.append("invoicePrefix", this.newEvent.invoicePrefix);
        formData.append("website", this.newEvent.website);

        if (this.newEvent.file) {
          formData.append("image", this.newEvent.file);
        }

        axiosAuth
          .post("events/", formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("getEventsAdmin");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Event gespeichert!"
            );
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            this.SnackbarShow("Danger", "Fehler!", this.authMessage.message);
            console.log(error.response);
            reject(error.response.data);
          });
      });
    },
  },
};
</script>
