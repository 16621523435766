<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-col cols="12" md="8">
      <v-container class="mb-15">
        <div
          style="
            margin-top: 100px;
            margin-bottom: 100px;
            width: 800px;
            max-width: 100%;
            overflow-x: hidden;
          "
          class="text-dark mx-1"
        >
          <h1>Widerrufsbelehrung</h1>
          <br />
          <p>Soweit Oelsnitz-Ticket.de im Namen von Veranstaltern Dienstleistungen aus dem Bereich der Freizeitveranstaltungen anbietet, insbesondere Tickets für Veranstaltungen, liegt gemäß § 312 g BGB kein sog. Fernabsatzvertrag im Sinne von § 312 g Absatz 2 BGB vor. Dies bedeutet, dass das für sog. Fernabsatzverträge gesetzlich vorgesehene zweiwöchige Widerrufs- und Rückgaberecht des Kunden durch eine gesetzliche Ausnahme ausgeschlossen ist. Jede Bestellung von Tickets ist damit unmittelbar nach Bestätigung durch Oelsnitz-Ticket.de namens des Veranstalters bindend und verpflichtet zur Abnahme und unverzüglichen Bezahlung der bestellten Karten.</p>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Datenschutz",
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
    };
  },
};
</script>