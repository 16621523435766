<template>
  <v-card
    class="card card-shadow border-radius-xl py-5 text-center"
    data-animation="true"
    :to="link ? `/admin/administration/client/${client._id}` : null"
  >
    <h5 class="font-weight-normal text-typo text-h5 mb-2 px-4">
      {{ `${client.firstname} ${client.lastname} ` }}
    </h5>
    <h6>{{ client.companyName }}</h6>
    <hr class="horizontal dark my-6" />
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">Adresse:</p>

      <p class="text-sm my-auto font-weight-light ml-auto">
        {{ `${client.street}, ${client.zipcode} ${client.city} ` }}
      </p>
    </div>
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">Telefon:</p>
      <p class="text-sm my-auto font-weight-light ml-auto">
        {{ `${client.phone ? client.phone : ""}` }}
      </p>
    </div>
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">E-Mail:</p>
      <p class="text-sm my-auto font-weight-light ml-auto">
        {{ `${client.email}` }}
      </p>
    </div>
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">Steuernummer:</p>
      <p class="text-sm my-auto font-weight-light ml-auto">
        {{ `${client.taxNumber}` }}
      </p>
    </div>
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">USt-IdNr:</p>
      <p class="text-sm my-auto font-weight-light ml-auto">
        {{
          `${client.vatNumber == undefined ? "keine Angabe" : client.vatNumber}`
        }}
      </p>
    </div>
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">Steuer-Id:</p>
      <p
        class="text-sm my-auto font-weight-light ml-auto"
        v-if="
          client.taxIdentificationNumber &&
          client.taxIdentificationNumber.length == 11
        "
      >
        {{
          `${
            client.taxIdentificationNumber == undefined ||
            client.taxIdentificationNumber == "undefined"
              ? "keine Angabe"
              : client.taxIdentificationNumber
          }`
        }}
      </p>
      <p
        class="text-sm my-auto font-weight-light ml-auto text-success"
        v-else-if="client.demoAccount"
      >
        DEMOACCOUNT
      </p>
      <p class="text-sm my-auto font-weight-light ml-auto text-danger" v-else>
        Steuer-Id erforderlich!
      </p>
    </div>
    <hr class="horizontal dark my-6" />
    <div class="d-flex text-body mx-2 px-4">
      <p class="mb-0 font-weight-normal text-body">PsTTG Status:</p>
      <p
        class="text-sm my-auto font-weight-light ml-auto text-success"
        v-if="client.psttg_status"
      >
        OK
      </p>
      <p
        class="text-sm my-auto font-weight-light ml-auto text-success"
        v-else-if="client.demoAccount"
      >
        DEMOACCOUNT
      </p>
      <p class="text-sm my-auto font-weight-light ml-auto text-danger" v-else>
        Übermittlung offen!
      </p>
    </div>
    <!-- <div v-if="report">
      <hr class="horizontal dark my-6" />
      <v-btn
        elevation="0"
        height="43"
        class="font-weight-bold text-uppercase btn-default btn-outline-default shadow-none py-2 px-6 me-2"
        color="transparent"
        small
        @click="csvExportEntries(selectedClientEvent, clientEventEntries)"
      >
        <v-icon>ni ni-archive-2 me-1</v-icon>
        Download Jahresauswertung
        {{ selectedYear ? selectedYear : currentYear }}
      </v-btn>
    </div> -->
  </v-card>
</template>

<script>
import administrationData from "@/mixins/administrationMixin";
export default {
  mixins: [administrationData],
  name: "ClientCard",
  props: {
    client: {
      type: Object,
      default: null,
    },
    link: {
      type: Boolean,
      default: true,
    },
    report: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>