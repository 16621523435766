<template>
  <v-row
    class="justify-center d-flex mt-n16 mx-0 bg-white position-relative border-radius-xl shadow px-4"
  >
    <v-col cols="12" md="8">
      <v-container class="mb-15">
        <div
          style="
            margin-top: 100px;
            margin-bottom: 100px;
            width: 800px;
            max-width: 100%;
            overflow-x: hidden;
          "
          class="text-dark mx-1"
        >
          <h1>Applinks</h1>
          <br />
          <p>Dynamic Applink:</p>
          <!-- <p>{{ dynamicApplink }}</p> -->
          <v-btn
            :elevation="0"
            @click="openEventLink()"
            class="font-weight-bold text-xs btn-default bg-gradient-warning"
            color="primary"
          >
            {{ dynamicApplink }}
          </v-btn>
        </div>
        <div></div>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Datenschutz",
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
    };
  },
  mounted() {
    console.log(this.$route);
  },
  computed: {
    dynamicApplink() {
      return this.$route.query.link || "no link";
    },
  },
  methods: {
    openEventLink() {
      console.log("clicked");
      let userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const isIpad = userAgent.match(/iPad/);
      const isAndroid = userAgent.match(/Android/);
      const isIphone = userAgent.match(/iPhone/);

      if (isAndroid || isIphone || isIpad) {
        try {
          window.location.replace(this.dynamicApplink);
        } catch (error) {
          alert(error);
          console.log(error);
        }
      }
    },
  },
};
</script>